import { FC } from 'react'
import TSvgProps from './type/svgPropsType'

const PenAndCardIcon: FC<TSvgProps> = (props) => {
  return (
    <svg
      height="25.35"
      viewBox="0 0 26 25.35"
      width="26"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="펜과 카드"
      {...props}
    >
      <desc>펜과 카드</desc>
      <g fill="#333" transform="translate(0 -5.915)">
        <path
          d="m89.777 63.326a2.82 2.82 0 0 0 -3.985 0l-5.556 5.558a.559.559 0 0 0 0 .79l.015.015-9.672 9.676a5.675 5.675 0 0 0 -1.579 2.385 2 2 0 0 0 .12 1.453l-.533.533a.558.558 0 1 0 .79.79l.534-.534a1.821 1.821 0 0 0 .847.2 4.5 4.5 0 0 0 2.99-1.662l9.672-9.676.015.015a.558.558 0 0 0 .79 0l5.556-5.558a2.823 2.823 0 0 0 -.004-3.985zm-19.536 19.544c-.44-.44-.16-1.285.744-2.308l1.563 1.563a3.1 3.1 0 0 1 -1.783.949.712.712 0 0 1 -.524-.204zm3.108-1.522-1.586-1.588 9.278-9.281.4.4-.79.79a.558.558 0 1 0 .79.79l.79-.79.4.4zm15.639-14.826-5.161 5.163-.808-.808 5.318-5.32a.558.558 0 1 0 -.79-.79l-5.318 5.32-.808-.808 5.161-5.163a1.7 1.7 0 0 1 2.406 2.407z"
          transform="translate(-64.601 -53.425)"
        />
        <path
          d="m105.746 112.82.04-1.119 1.119-.04a.558.558 0 0 0 .538-.538l.059-1.639a.558.558 0 1 0 -1.116-.04l-.04 1.12-1.119.04a.558.558 0 0 0 -.538.538l-.04 1.119-1.119.04a.559.559 0 0 0 .02 1.117h.02l1.638-.059a.558.558 0 0 0 .538-.539z"
          transform="translate(-97.241 -97.237)"
        />
        <path d="m6.678 22.723a.558.558 0 0 0 .395-.164l9.565-9.569a.559.559 0 0 0 0-.79l-6.119-6.121a.558.558 0 0 0 -.79 0l-9.565 9.569a.559.559 0 0 0 0 .79l6.119 6.122a.558.558 0 0 0 .395.163zm3.446-15.46 5.33 5.332-8.776 8.78-5.33-5.332z" />
      </g>
    </svg>
  )
}

export default PenAndCardIcon
