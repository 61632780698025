import { FC } from 'react'
import TSvgProps from './type/svgPropsType'

const SliderIcon: FC<TSvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.209"
      height="16"
      viewBox="0 0 15.209 16"
      aria-label="슬라이더"
      {...props}
    >
      <desc>슬라이더</desc>
      <g fill="#fff">
        <path
          d="M506.271 376.121h-1.184a2.926 2.926 0 00-5.511 0h-6.514a1 1 0 000 2h6.514a2.926 2.926 0 005.511 0h1.184a1 1 0 000-2zm-3 1a.941.941 0 11-.942-.942.941.941 0 01.944.942z"
          transform="translate(-492.062 -364.062)"
        ></path>
        <path
          d="M506.271 371.062h-6.648a2.926 2.926 0 00-5.51 0h-1.051a1 1 0 000 2h1.051a2.925 2.925 0 005.51 0h6.648a1 1 0 000-2zm-8.461 1a.942.942 0 11-.942-.941.942.942 0 01.942.941z"
          transform="translate(-492.062 -364.062)"
        ></path>
        <path
          d="M493.062 368h6.514a2.926 2.926 0 005.511 0h1.184a1 1 0 000-2h-1.184a2.926 2.926 0 00-5.511 0h-6.514a1 1 0 000 2zm8.328-1a.941.941 0 11.941.942.942.942 0 01-.941-.942z"
          transform="translate(-492.062 -364.062)"
        ></path>
      </g>
    </svg>
  )
}

export default SliderIcon
