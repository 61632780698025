import * as React from 'react'
import styled from 'styled-components'

export interface TextFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string
  label?: Exclude<React.ReactNode, boolean | null | undefined>
  error?: Exclude<React.ReactNode, boolean | null | undefined>
}

const TextField: React.ForwardRefRenderFunction<
  HTMLInputElement,
  TextFieldProps
> = ({ label, error, children, className, ...props }, ref) => {
  return (
    <StyledWrapper>
      {label && <div className="label">{label}</div>}
      <input
        ref={ref}
        type="text"
        className={className}
        data-error={!!error}
        {...props}
      />
      {error && <div className="error">{error}</div>}
      {children}
    </StyledWrapper>
  )
}

export default React.forwardRef(TextField)

const StyledWrapper = styled.div`
  width: 100%;
  .label {
    font-size: 16px;
    font-weight: 500;
    color: #333;
  }
  input {
    width: 100%;
    outline: none;
    border: none;
    font-size: 14px;

    flex-shrink: 1;

    background-color: white;
    border: 1px solid #e8e8e8;
    padding: 5px 15px;
    border-radius: 5px;
    &[disabled] {
      color: #999999;
      background-color: #eeeeee;
    }
    &[data-error='true'] {
      border: 1px solid #ef2929;
    }
  }
  .error {
    font-size: 12px;
    margin-top: 10px;
    color: #ef2929;
  }
`
