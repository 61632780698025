import { Overrides } from '@material-ui/core/styles/overrides'
import breakpoints from '../breakpoints'
import spacing from '../spacing'

const overrides: Overrides = {
  MuiContainer: {
    root: {
      paddingLeft: 0,
      paddingRight: 0,
      [breakpoints.up('sm')]: {
        paddingLeft: spacing(3),
        paddingRight: spacing(3),
      },
      // TODO: delete this code when update mui https://github.com/mui-org/material-ui/pull/19505/files
      [breakpoints.up('md')]: {
        paddingLeft: spacing(3),
        paddingRight: spacing(3),
      },
    },
    maxWidthLg: {
      [breakpoints.up('lg')]: {
        maxWidth: 1100 + spacing(3) * 2,
      },
    },
  },
}

export default overrides
