import { Overrides } from '@material-ui/core/styles/overrides'
import badge from './badge'
import button from './button'
import chip from './chip'
import container from './container'
import dialog from './dialog'
import form from './form'
import menu from './menu'
import stepper from './stepper'

const overrides: Overrides = {
  MuiToolbar: {
    regular: {
      minHeight: 50,
    },
  },

  ...button,
  ...form,
  ...dialog,
  ...stepper,
  ...container,
  ...menu,
  ...badge,
  ...chip,
}

export default overrides
