import { useCallback, useMemo } from 'react'
import * as React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useSnackbar } from 'notistack'
import styled from 'styled-components'
import { Close } from 'svgIcons'
import { SharePlatFormType } from 'generated/graphql'
import Button from '@app.components/Button'
import Modal, { IFModalProps } from 'app.components/Modal/Modal'
import TextField from 'app.components/inputs/TextField'
import { mobileBreakPoint } from 'app.styled/media'
import { KakaoShareButton } from './KakaoShareButton'
import facebookLink from './index.module'

interface ShareModalProps extends IFModalProps {
  title?: string
  quote?: string
  shareLink: string
  ogImage?: string
  onClick?: (platform: SharePlatFormType) => void
}

const ShareModal: React.FC<ShareModalProps> = ({
  onClose,
  quote = '',
  shareLink,
  title = '공유하기',
  ogImage,
  onClick = () => {},
  ...props
}) => {
  const label = useMemo(() => `[링커리어] ${quote}`, [quote])
  const { enqueueSnackbar } = useSnackbar()

  const handleFacebookShareButtonClick = useCallback(() => {
    const shareURL = facebookLink(shareLink, { quote })

    window.open(shareURL)

    onClick(SharePlatFormType.FACEBOOK)
  }, [shareLink, quote])

  const handleTwitterShareButtonClick = useCallback(() => {
    const shareUrl = `https://twitter.com/intent/tweet?text=${encodeURI(
      label,
    )}&hashtags=링커리어,linkareer,대학생,공모전,대외활동&url=${encodeURI(
      shareLink,
    )}`

    window.open(shareUrl)

    onClick(SharePlatFormType.TWITTER)
  }, [shareLink, quote])

  const handleNaverShareButtonClick = useCallback(() => {
    const shareURL = `https://share.naver.com/web/shareView?url=${encodeURI(
      shareLink,
    )}&title=${encodeURI(label)}`

    window.open(shareURL)

    onClick(SharePlatFormType.NAVER)
  }, [quote, shareLink])

  const handleBandShareButtonClick = useCallback(() => {
    const shareURL = `https://band.us/plugin/share?body=${encodeURI(
      `${label}\n${encodeURI(shareLink)}`,
    )}`

    window.open(shareURL)
    onClick(SharePlatFormType.NAVERBAND)
  }, [shareLink, quote])

  const handleKTShareButtonClick = useCallback(() => {
    onClick(SharePlatFormType.KAKAO)
  }, [shareLink, quote])

  const handleURLShareButtonClick = useCallback(() => {
    navigator.clipboard.writeText(shareLink)
    onClick(SharePlatFormType.URL)
    enqueueSnackbar('복사되었습니다.')
    onClose && onClose()
  }, [shareLink, quote])

  return (
    <Modal onClose={onClose} {...props}>
      <StyledWrapper>
        <div className="modal-header">
          <div className="header-title">{title}</div>
          <div className="close-btn" onClick={onClose}>
            <Close />
          </div>
        </div>
        <div className="modal-sub-title">
          <div className="share-btn" onClick={handleFacebookShareButtonClick}>
            <img src="/static/images/channel/facebook.svg" alt="" />
          </div>
          <div className="share-btn" onClick={handleTwitterShareButtonClick}>
            <img src="/static/images/channel/twitter.svg" alt="" />
          </div>
          <div className="share-btn" onClick={handleNaverShareButtonClick}>
            <img src="/static/images/channel/naver.svg" alt="" />
          </div>
          <div className="share-btn" onClick={handleBandShareButtonClick}>
            <img src="/static/images/channel/band.svg" alt="" />
          </div>
          <div className="share-btn">
            <KakaoShareButton
              onClick={handleKTShareButtonClick}
              url={shareLink}
              text={label}
              ogImage={ogImage}
              title={title}
            >
              <img src="/static/images/channel/kakaotalk.svg" alt="" />
            </KakaoShareButton>
          </div>
        </div>
        <div className="bottom-form">
          <TextField className="input" value={shareLink} disabled />
          <CopyToClipboard text={shareLink}>
            <Button className="btn" onClick={handleURLShareButtonClick}>
              복사
            </Button>
          </CopyToClipboard>
        </div>
      </StyledWrapper>
    </Modal>
  )
}

export default ShareModal

const StyledWrapper = styled.div`
  width: 100%;
  max-width: 450px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;

  .close-btn {
    align-self: flex-end;
    color: #999999;
    cursor: pointer;

    svg {
      width: 15px;
      height: 15px;
      path {
        fill: #999999;
      }
    }
  }
  .modal-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
    border-bottom: 1px solid #eeeeee;
    .header-title {
      font-size: 18px;
      font-weight: 500;
    }
  }
  .modal-title {
    font-size: 24px;
    font-weight: 600;
  }
  .modal-sub-title {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    font-size: 18px;
    margin: 40px 0;
    width: 100%;
    font-weight: 500;
    gap: 40px;
    padding: 0 30px;
    .share-btn {
      display: flex;
      justify-content: center;
      cursor: pointer;
    }
  }
  .modal-description {
    font-size: 16px;
    margin: 20px 0 30px;
    text-align: center;
  }
  .bottom-form {
    display: flex;
    gap: 4px;
    width: 100%;
    margin-bottom: 20px;
    .input {
      width: 100%;
      height: 100%;
      border: 1px solid #cccccc;
    }
    .btn {
      font-size: 16px;
      padding: 13px 15px;
      flex-shrink: 0;
    }
  }
  ${mobileBreakPoint} {
    max-width: 350px;
  }
`
