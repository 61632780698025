import { FC, memo } from 'react'

interface LinkareerLogoWithNameProps {
  className?: string
  noColor?: boolean
}

const LinkareerLogoWithName: FC<LinkareerLogoWithNameProps> = ({
  noColor = false,
  ...props
}) => {
  return (
    <svg
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      width="213.637"
      height="50"
      viewBox="0 0 213.637 50"
      aria-label="링커리어"
      {...props}
    >
      <desc>링커리어</desc>
      <g>
        <path
          fill={noColor ? 'currentColor' : '#003056'}
          d="M279.828 669.549h18.756v-6.692h-11.271v-22.19h-7.485z"
          transform="translate(-1140 -1030) translate(860.172 409.846)"
        />
        <path
          fill={noColor ? 'currentColor' : '#003056'}
          d="M0 0h7.925v28.882H0z"
          transform="translate(-1140 -1030) translate(1163.511 1050.513)"
        />
        <path
          fill={noColor ? 'currentColor' : '#003056'}
          d="M302.939 640.667v28.882h7.221v-17.435l10.74 17.435h7.309v-28.882H320.9v15.762l-9.774-15.762z"
          transform="translate(-1140 -1030) translate(873.692 409.846)"
        />
        <path
          fill={noColor ? 'currentColor' : '#003056'}
          d="M322.717 669.549h7.132v-8.717l2.642-2.906 7.132 11.623h8.805L337.6 653.7l9.774-13.032h-8.541l-8.894 11.183v-11.184h-7.221z"
          transform="translate(-1140 -1030) translate(885.261 409.846)"
        />
        <path
          fill={noColor ? 'currentColor' : '#01a0ff'}
          d="M360.45 644.3l-1.331 3.346s-2.026-3.7-8.366-3.7a10.725 10.725 0 00-8.893 4.14 13.775 13.775 0 00-2.642 7.484 11.631 11.631 0 004.051 9.51c3.17 3.081 8.012 2.994 8.012 2.994s5.372-.265 7.838-3.346l1.32 2.905h4.842V644.3zm-5.085 15.783a5.906 5.906 0 01-4.1 1.552 7.269 7.269 0 01-4-1.255 5.818 5.818 0 01-1.75-4.368 6.035 6.035 0 011.536-4.283 5.6 5.6 0 014.21-1.915 5.757 5.757 0 014.294 1.816 6.637 6.637 0 011.452 4.381 6.3 6.3 0 01-1.642 4.07z"
          transform="translate(-1140 -1030) translate(894.914 411.763)"
        />
        <path
          fill={noColor ? 'currentColor' : '#01a0ff'}
          d="M358.717 644.222v23.247h6.956V654s.352-3.522 4.579-3.522a4.55 4.55 0 013.874 2.113l3.363-4.425a10.483 10.483 0 00-3.137-2.939 5.992 5.992 0 00-6.754.363 17.332 17.332 0 00-2.03 2.081l-1.651-3.446z"
          transform="translate(-1140 -1030) translate(906.321 411.926)"
        />
        <path
          fill={noColor ? 'currentColor' : '#01a0ff'}
          d="M402.773 644.222v23.247h6.955V654s.353-3.522 4.579-3.522a4.553 4.553 0 013.875 2.113l3.362-4.425a10.459 10.459 0 00-3.137-2.939 5.99 5.99 0 00-6.752.363 17.422 17.422 0 00-2.032 2.081l-1.65-3.446z"
          transform="translate(-1140 -1030) translate(932.093 411.926)"
        />
        <path
          fill={noColor ? 'currentColor' : '#01a0ff'}
          d="M393.413 655.609c.181-4.325-2.758-8.232-2.758-8.232a10.149 10.149 0 00-8.014-3.566 10.5 10.5 0 00-7.881 2.861 12.821 12.821 0 00-3.566 9.423 13.48 13.48 0 003.181 8.53 12.656 12.656 0 007.995 3.7c7.478 0 9.253-4.436 9.253-4.436l-4.05-3.3a6.95 6.95 0 01-5.042 1.81 4.517 4.517 0 01-3.582-1.732 6.512 6.512 0 01-1.206-2.587h13.043c1.894-.094 2.627-2.471 2.627-2.471zm-15.487-2.344a4.86 4.86 0 014.623-3.682 5.186 5.186 0 014.951 3.682z"
          transform="translate(-1140 -1030) translate(913.612 411.678)"
        />
        <path
          fill={noColor ? 'currentColor' : '#01a0ff'}
          d="M409.356 655.609c.182-4.325-2.758-8.232-2.758-8.232a10.145 10.145 0 00-8.012-3.566 10.491 10.491 0 00-7.881 2.861 12.821 12.821 0 00-3.566 9.423 13.48 13.48 0 003.181 8.53 12.657 12.657 0 007.995 3.7c7.476 0 9.253-4.436 9.253-4.436l-4.051-3.3a6.949 6.949 0 01-5.042 1.81 4.522 4.522 0 01-3.582-1.732 6.522 6.522 0 01-1.2-2.587h13.043c1.888-.094 2.62-2.471 2.62-2.471zm-15.487-2.344a4.862 4.862 0 014.623-3.682 5.187 5.187 0 014.953 3.682z"
          transform="translate(-1140 -1030) translate(922.939 411.678)"
        />
        <g fill={noColor ? 'currentColor' : '#01a0ff'}>
          <path
            d="M378.977 634.71a55.765 55.765 0 006.922-.285l.1.967a65.959 65.959 0 01-8.11.3v-4.1h5.18v-2.154h-5.18v-.983h6.273v4.088h-5.18zm1.108 4.72c0-1.568 1.839-2.566 4.4-2.566s4.4 1.014 4.4 2.582v.571c0 1.583-1.837 2.582-4.4 2.582s-4.4-1-4.4-2.582zm7.73.539v-.475c0-.949-1.407-1.679-3.325-1.679s-3.327.745-3.327 1.679v.475c0 .951 1.411 1.68 3.327 1.68s3.324-.729 3.324-1.68zm1-3.485h-1.108v-8.759h1.108z"
            transform="translate(-1140 -1030) translate(1295.416 1030) translate(-377.883 -627.725)"
          />
          <path
            d="M398.336 642.2h-1.109v-8.364h-3.88c-.38 1.758-1.694 3.042-5.784 5.227l-.522-.9c3.866-2.076 4.974-3.168 5.276-4.516l-5.4.127-.032-1 5.513-.093v-2.615h-5.211v-1.014h6.305v3.138c0 .222-.016.428-.016.632h3.753v-5.1h1.109z"
            transform="translate(-1140 -1030) translate(1295.416 1030) translate(-372.618 -627.725)"
          />
          <path
            d="M397.641 637.609h2.171a31.452 31.452 0 004.8-.317l.127.967a33.685 33.685 0 01-5.211.349h-2.994v-5.544h5.243v-3.184h-5.243v-.98h6.353v5.148h-5.245zm9.727 4.593h-1.125v-14.477h1.125z"
            transform="translate(-1140 -1030) translate(1295.416 1030) translate(-366.973 -627.725)"
          />
          <path
            d="M416.948 642.2h-1.109v-8.267h-3.248v.49c0 2.645-1.33 4.2-3.563 4.2s-3.565-1.553-3.565-4.2v-1.488c0-2.629 1.331-4.183 3.565-4.183 2.216 0 3.547 1.553 3.563 4.167h3.248v-5.2h1.109zm-5.449-9.06c0-2.409-.983-3.344-2.471-3.344s-2.473.935-2.473 3.344v1.092c0 2.409.983 3.358 2.473 3.358s2.471-.949 2.471-3.358z"
            transform="translate(-1140 -1030) translate(1295.416 1030) translate(-361.749 -627.725)"
          />
        </g>
      </g>
    </svg>
  )
}

export default memo(LinkareerLogoWithName)
