import { ButtonHTMLAttributes, ReactNode } from 'react'
import * as React from 'react'
import styled, { FlattenSimpleInterpolation } from 'styled-components'
import { getCss } from './Button.module'
import { ColorType, VariantType } from './Button.type'

// TODO: outline -> outlined로 변경
export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
  color?: ColorType
  variant?: VariantType
  startIcon?: ReactNode
  endIcon?: ReactNode
}

const Button: React.FC<ButtonProps> = ({
  className,
  color = 'primary',
  variant = 'contained',
  children,
  startIcon,
  endIcon,
  ...props
}) => {
  return (
    <StyledWrapper
      className={className}
      customCss={getCss(color, variant)}
      {...props}
    >
      {startIcon && <span className="start-icon">{startIcon}</span>}
      {children}
      {endIcon && <span className="end-icon">{endIcon}</span>}
    </StyledWrapper>
  )
}

export default Button

const StyledWrapper = styled.button<{
  customCss?: FlattenSimpleInterpolation
}>`
  border: none;
  background: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 8px 13px;

  font-family: inherit;
  font-weight: bold;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  color: #ffffff;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  .start-icon {
    display: inherit;
    margin-right: 4px;
  }
  .end-icon {
    display: inherit;
    margin-left: 4px;
  }

  &[disabled] {
    cursor: default;
    background-color: #dbdbdb;
    &:hover {
      background-color: #eee;
    }
  }
  ${({ customCss }) => customCss}
`
