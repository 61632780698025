import { FC } from 'react'
import TSvgProps from './type/svgPropsType'

const PersonLongBody: FC<TSvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28.88"
      height="27.76"
      viewBox="0 0 28.88 27.76"
      aria-label="사람"
      {...props}
    >
      <desc>사람</desc>
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <circle cx="14.44" cy="7.165" r="6.165"></circle>
        <path d="M27.88 26.76a13.44 13.44 0 00-26.88 0"></path>
      </g>
    </svg>
  )
}

export default PersonLongBody
