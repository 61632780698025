import { FC } from 'react'
import TSvgProps from './type/svgPropsType'

const StackofCardIcon: FC<TSvgProps> = (props) => {
  return (
    <svg
      height="22.75"
      viewBox="0 0 26 22.75"
      width="26"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="상품 안내"
      {...props}
    >
      <desc>상품 안내</desc>
      <g fill="#333" transform="translate(0 -26.806)">
        <path
          d="m25.725 236.744-.7-.437a.587.587 0 0 0 -.711.928l-9.344 5.859-13.287-8.334a.587.587 0 0 0 -.711-.928l-.7.437a.584.584 0 0 0 0 .991l14.385 9.02a.59.59 0 0 0 .627 0l10.439-6.546a.584.584 0 0 0 0-.991z"
          transform="translate(0 -194.815)"
        />
        <path
          d="m25.725 185.415-.7-.437a.587.587 0 0 0 -.711.928l-9.344 5.859-13.287-8.334a.587.587 0 0 0 -.711-.928l-.7.437a.584.584 0 0 0 0 .991l14.385 9.02a.59.59 0 0 0 .627 0l10.439-6.546a.584.584 0 0 0 0-.991z"
          transform="translate(0 -146.493)"
        />
        <path d="m.275 34.433 14.385 9.02a.59.59 0 0 0 .627 0l10.439-6.546a.584.584 0 0 0 0-.991l-14.385-9.016a.59.59 0 0 0 -.627 0l-10.439 6.542a.584.584 0 0 0 0 .991zm10.752-6.349 13.281 8.327-9.335 5.854-13.281-8.328z" />
        <path
          d="m248.815 154.838 2.049 1.285a.586.586 0 1 0 .627-.991l-2.049-1.285a.586.586 0 1 0 -.627.991z"
          transform="translate(-233.92 -119.514)"
        />
        <path
          d="m185.834 115.179 2.049 1.285a.586.586 0 1 0 .627-.991l-2.049-1.285a.586.586 0 1 0 -.627.991z"
          transform="translate(-174.644 -82.178)"
        />
        <path
          d="m145.281 147.35-5.754-3.608a.586.586 0 1 0 -.627.991l5.754 3.608a.586.586 0 1 0 .627-.991z"
          transform="translate(-130.471 -110.002)"
        />
      </g>
    </svg>
  )
}

export default StackofCardIcon
