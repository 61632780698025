import {
  FunctionComponent,
  createContext,
  useContext,
  useCallback,
  useState,
} from 'react'
import CustomerService from './CustomerService'

const CustomerMessageFormContext = createContext<any>(null)

export const useOpenCustomerMessageForm = () => {
  return useContext(CustomerMessageFormContext)
}

interface CustomerMessageFormProps {
  open: boolean
  onRequestClose: () => any
}

const CustomerMessageForm: FunctionComponent<CustomerMessageFormProps> = ({
  open,
  onRequestClose,
}) => {
  if (!open) {
    return null
  }

  return <CustomerService onRequestClose={onRequestClose} />
}

const CustomerMessageFormContainer: FunctionComponent = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false)

  const handleOpen = useCallback(() => setOpen(true), [])
  const handleClose = useCallback(() => setOpen(false), [])

  return (
    <>
      <CustomerMessageFormContext.Provider value={handleOpen}>
        {children}
      </CustomerMessageFormContext.Provider>
      <CustomerMessageForm open={open} onRequestClose={handleClose} />
    </>
  )
}

export default CustomerMessageFormContainer
