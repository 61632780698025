export const breakpoints = [1280, 960]

export const tabletBreakPointSize = breakpoints[0]
export const mobileBreakPointSize = breakpoints[1]

export const tabletBreakPoint = `@media (max-width: ${tabletBreakPointSize}px)`
export const mobileBreakPoint = `@media (max-width: ${mobileBreakPointSize}px)`

// /**
//  * MUI에 정의되어있는 ViewSize 분기
//  */
// enum ViewSizes {
//   XS = '0px',
//   SM = '600px',
//   MD = '960px',
//   LG = '1280px',
//   XL = '1920px',
// }

// /**
//  * 미디어 쿼리에 적용될 min, max 값
//  */
// enum ViewSizeArea {
//   MIN = 'min',
//   MAX = 'max',
// }

// type TViewSizes = keyof typeof ViewSizes

// type TBreakpoint<T extends ViewSizeArea> = Record<
//   Lowercase<TViewSizes>,
//   `@media screen and (${T}-width: ${string})`
// >

// /**
//  * 미디어 쿼리를 적용하기 위한 함수입니다
//  * @desc MUI theme.breakpoints.up(${size})
//  */
// const up: TBreakpoint<ViewSizeArea.MIN> = {
//   xs: `@media screen and (min-width: ${ViewSizes.XS})`,
//   lg: `@media screen and (min-width: ${ViewSizes.LG})`,
//   md: `@media screen and (min-width: ${ViewSizes.MD})`,
//   sm: `@media screen and (min-width: ${ViewSizes.SM})`,
//   xl: `@media screen and (min-width: ${ViewSizes.XL})`,
// }

// /**
//  * 미디어 쿼리를 적용하기 위한 함수입니다
//  * @desc MUI theme.breakpoints.down(${size})
//  */
// const down: TBreakpoint<ViewSizeArea.MAX> = {
//   xs: `@media screen and (max-width: ${ViewSizes.XS})`,
//   lg: `@media screen and (max-width: ${ViewSizes.LG})`,
//   md: `@media screen and (max-width: ${ViewSizes.MD})`,
//   sm: `@media screen and (max-width: ${ViewSizes.SM})`,
//   xl: `@media screen and (max-width: ${ViewSizes.XL})`,
// }

// const breakpoint = {
//   up,
//   down,
// }

// export default breakpoint
