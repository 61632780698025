import { FC } from 'react'
import TSvgProps from './type/svgPropsType'

const Person: FC<TSvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="15"
      viewBox="0 0 16 15"
      aria-label="사람"
      {...props}
    >
      <desc>사람</desc>
      <path
        fill="currentColor"
        d="M12.144 8.306a5.313 5.313 0 00.969-3.054 5.114 5.114 0 10-10.225 0 5.3 5.3 0 00.987 3.075A6.575 6.575 0 000 13.972a1 1 0 102 0c0-1.78 1.494-3.4 3.541-4.144a4.813 4.813 0 004.935-.009c2.057.736 3.524 2.34 3.524 4.153a1 1 0 102 0 6.5 6.5 0 00-3.856-5.666zm-6.255-.718a3.221 3.221 0 01-1-2.335 3.114 3.114 0 116.225 0 3.219 3.219 0 01-.992 2.327 3.018 3.018 0 01-4.232.008z"
      ></path>
    </svg>
  )
}

export default Person
