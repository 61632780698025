import { Overrides } from '@material-ui/core/styles/overrides'
import palette from '../palette'

const overrides: Overrides = {
  MuiButtonGroup: {
    root: {
      borderRadius: 0,
    },
    groupedOutlined: {
      borderRadius: 0,
      '&:not(:first-child)': {
        marginLeft: 0,
      },
    },
  },

  MuiButton: {
    root: {
      fontWeight: 500,
      borderRadius: 5,
      minWidth: 0,
      fontSize: 16,
      lineHeight: 1.5,
      padding: '13px 15px',
    },
    outlined: {
      '&:hover': {
        backgroundColor: '#F4F4F4',
      },
    },
    outlinedPrimary: {
      borderColor: palette.primary.main,
      '&:hover': {
        backgroundColor: '#F2FAFF',
      },
    },
    text: {
      borderRadius: 0,
      '&:hover': {
        backgroundColor: '#F4F4F4',
      },
    },
    contained: {
      fontWeight: 'bold',
      backgroundColor: '#eeeeee',
      '&$disabled': {
        color: '#fff',
        backgroundColor: '#eeeeee',
      },
    },

    startIcon: {
      marginRight: 8,
    },
    endIcon: {
      marginLeft: 8,
    },

    sizeSmall: {
      fontSize: 12,
      padding: '11px 20px',
    },
    sizeLarge: {
      fontSize: 16,
      padding: '13px 30px',
    },
  },

  MuiIconButton: {
    root: {
      '&:hover': {
        backgroundColor: 'inherit',
      },
    },
  },
}

export default overrides
