const color = {
  primary_1: '#003056',
  primary_2: '#01a0ff',
  secondary_1: '#ff0066',
  text_1: '#333333',
  text_2: '#666666',
  text_3: '#999999',
  hint_1: '#bbbbbb',
  error_1: '#ef2929',
} as const

export type TColor = typeof color

const line = {
  line_1: '#cccccc',
  line_2: '#dddddd',
  line_3: '#e8e8e8',
} as const

export type TLine = typeof line

const background = {
  bg_1: '#eeeeee',
  bg_2: '#f4f4f4',
  bg_3: '#fafafa',
  bg_4: '#f2faff',
  bg_5: '#aaaaaa',
  bg_6: '#e1005a',
  bg_7: '#008bde',
  bg_8: '#ffffff',
} as const

export type TBackground = typeof background

type TBreakpoint = 'sm' | 'md' | 'lg'

type TBreakpointMap = {
  [key in TBreakpoint]: [up: number, down: number]
}

const BREAK_POINT_MAP: TBreakpointMap = {
  sm: [600, 900],
  md: [960, 1280],
  lg: [1280, 1920],
}

const breakpoints = {
  up: (breakpoint: TBreakpoint) =>
    `@media screen and (min-width: ${BREAK_POINT_MAP[breakpoint][0]}px)`,
  down: (breakpoint: TBreakpoint) =>
    `@media screen and (max-width: ${BREAK_POINT_MAP[breakpoint][1]}px)`,
}

export type TBreakpoints = typeof breakpoints

const styledTheme = {
  color,
  line,
  background,
  breakpoints,
}

export default styledTheme
