import { TAppversionSemver } from 'constants/verifyAppVersion/verifyAppVersionList'

export type TAppVersion = `${'i' | 'a'}${TAppversionSemver}`
export type TCompareVersion = {
  ios: TAppversionSemver
  aos: TAppversionSemver
}

type TValidateAppVersion = (
  currentVersion: Array<number>,
  comparedVersion: Array<number>,
) => boolean

type TVerifyAppVersion = (
  appVersion: TAppVersion,
  comparedVersion: TCompareVersion,
) => boolean

/**
 * @desc 사용중인 앱의 버전이 비교할 버전보다 높으면 true, 낮으면 false를 반환합니다
 * @param currentVersion 사용중인 앱 버전
 * @param comparedVersion 상수로 지정된 비교할 버전
 * @returns {boolean}
 */
const validateAppVersion: TValidateAppVersion = (
  currentVersion,
  comparedVersion,
): boolean => {
  for (let i = 0; i < 3; i++) {
    if (currentVersion[i] > comparedVersion[i]) {
      return true
    } else if (currentVersion[i] < comparedVersion[i]) {
      return false
    }
  }

  return true
}

/**
 * @desc 사용중인 앱의 버전이 특정 버전 이상인지 확인합니다
 * @param appVersion 현재 사용중인 앱 버전 정보
 * @returns {boolean}
 */
export const verifyAppVersion: TVerifyAppVersion = (
  appVersion,
  compareVersion,
) => {
  const [applicationOs, ...applicationVersion] = appVersion
  const comparedVersion =
    applicationOs === 'i' ? compareVersion.ios : compareVersion.aos

  const currentVersionArray = applicationVersion.join('').split('.').map(Number)
  const comparedVersionArray = comparedVersion.split('.').map(Number)

  const isValidateAppVersion = validateAppVersion(
    currentVersionArray,
    comparedVersionArray,
  )

  return isValidateAppVersion
}
