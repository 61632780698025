import { FC } from 'react'
import TSvgProps from './type/svgPropsType'

const CheckIcon: FC<TSvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="10"
      viewBox="0 0 14 10"
      aria-label="체크"
      fill="currentColor"
      {...props}
    >
      <desc>체크</desc>
      <path d="M4.851 10a1.04 1.04 0 01-.731-.294L.3 5.98a1.053 1.053 0 010-1.471 1.1 1.1 0 011.5 0l3.057 3.007L12.2.294a1.1 1.1 0 011.5 0 1.023 1.023 0 010 1.471L5.593 9.706a1.04 1.04 0 01-.742.294z"></path>
    </svg>
  )
}

export default CheckIcon
