import { FunctionComponent } from 'react'
import classNames from 'classnames'
import Link, { LinkProps } from 'next/link'
import styled from 'styled-components'
import {
  Omit,
  mapBulmaProps,
  BulmaProps,
  HTMLButtonProps,
  HTMLAnchorProps,
  HTMLDivProps,
} from './base'

export interface ButtonOwnProps {
  loading?: boolean
  static?: boolean
  small?: boolean
  large?: boolean
  white?: boolean
}

export function mapButtonProps<T extends ButtonOwnProps>(
  props: T,
): Omit<T, keyof ButtonOwnProps> {
  // TODO: remove any when typescript 3.2 and generic rest release
  const {
    loading,
    static: isStatic,
    small,
    large,
    white,
    ...other
  } = props as any
  const className = classNames(other.className, {
    'is-loading': loading,
    'is-static': isStatic,
    'is-small': small,
    'is-large': large,
    'is-white': white,
  })
  return { ...other, className }
}

export interface ButtonProps
  extends HTMLButtonProps,
    BulmaProps,
    ButtonOwnProps {}

export const Button: FunctionComponent<ButtonProps> = (props) => (
  <button {...mapBulmaProps(mapButtonProps(props), 'button')} />
)

export interface AnchorButtonProps
  extends HTMLAnchorProps,
    BulmaProps,
    ButtonOwnProps {}

export const AnchorButton: FunctionComponent<AnchorButtonProps> = (props) => (
  <a {...mapBulmaProps(mapButtonProps(props), 'button')} />
)

export interface LinkButtonProps
  extends LinkProps,
    BulmaProps,
    ButtonOwnProps {}

export const LinkButton: FunctionComponent<LinkButtonProps> = (props) => (
  <Link {...mapBulmaProps(mapButtonProps(props), 'button')} />
)

export interface ButtonsProps extends HTMLDivProps, BulmaProps {}

export const Buttons: FunctionComponent<ButtonsProps> = (props) => (
  <div {...mapBulmaProps(props, 'buttons')} />
)

export const BorderlessButton = styled((props) => <Button {...props} />)`
  border: none !important;
  background-color: rgba(0, 0, 0, 0);
`
