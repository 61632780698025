import { useMemo } from 'react'
import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  split,
} from '@apollo/client'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import { createUploadLink } from 'apollo-upload-client'
import { sha256 } from 'crypto-hash'
import merge from 'deepmerge'
import isEqual from 'lodash/isEqual'
import fragmentIntrospectionResult from 'generated/fragment-matcher'
import { ApolloAPMLink } from 'utils/apm'
import {
  LINKAREER_API_BASE_URL,
  LINKAREER_COMMUNITY_API_BASE_URL,
} from 'utils/config'

const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'

let apolloClient: ApolloClient<NormalizedCacheObject>

const createApolloClient = () => {
  const isSsr = typeof window === 'undefined'
  const isBrowser = !isSsr

  const cache = new InMemoryCache({
    possibleTypes: fragmentIntrospectionResult.possibleTypes,
  })

  const mainUri = `${LINKAREER_API_BASE_URL}/graphql`
  const communityUri = `${LINKAREER_COMMUNITY_API_BASE_URL}/graphql`

  const mainHttpLink = createUploadLink({
    uri: mainUri,
    credentials: 'include',
    headers: {
      device: 'web',
    },
  })
  const communityHttpLink = createUploadLink({
    uri: communityUri,
    credentials: 'include',
  })

  const persistedQueriesLink = createPersistedQueryLink({
    sha256,
    useGETForHashedQueries: true,
  })

  const mainApiLink = persistedQueriesLink
    .concat(new ApolloAPMLink())
    .concat(mainHttpLink)

  const communityApiLink = persistedQueriesLink
    .concat(new ApolloAPMLink())
    .concat(communityHttpLink)

  const link = split(
    (operation) => operation.getContext().clientName === 'community',
    communityApiLink,
    mainApiLink,
  )

  const apolloClient = new ApolloClient({
    cache,
    link,
    ...(isBrowser && {
      headers: {
        pragma: 'no-cache',
        'cache-control': 'no-cache',
      },
      fetchOptions: {
        cache: 'no-cache',
      },
    }),
    ssrMode: isSsr,
    ssrForceFetchDelay: 100,
  })

  return apolloClient
}

export const initializeApollo = (
  initialState: Partial<NormalizedCacheObject> | null = null,
) => {
  const _apolloClient = apolloClient ?? createApolloClient()

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s)),
        ),
      ],
    })

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data)
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}

/** ssr에서 fetch된 결과를 client에 hydrate하기 위해 필요한 함수 (in pages) */
export const addApolloState = (
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: Record<string, any>,
) => {
  const resultPageProps = {
    ...pageProps,
    props: {
      ...pageProps?.props,
      [APOLLO_STATE_PROP_NAME]: client.cache.extract(),
    },
  }

  return resultPageProps
}

export const useApollo = (pageProps: Record<string, any>) => {
  const state = pageProps?.props?.[APOLLO_STATE_PROP_NAME]
  const client = useMemo(() => initializeApollo(state), [state])

  return { client }
}
