import {
  HTMLAttributes,
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  LabelHTMLAttributes,
  InputHTMLAttributes,
  ImgHTMLAttributes,
  ProgressHTMLAttributes,
  TableHTMLAttributes,
  TextareaHTMLAttributes,
} from 'react'
import classNames from 'classnames'

export enum Intent {
  PRIMARY,
  WARNING,
  DANGER,
  INFO,
}

export function getIntentClass(intent: Intent) {
  switch (intent) {
    case Intent.PRIMARY: {
      return 'is-primary'
    }
    case Intent.INFO: {
      return 'is-info'
    }
    case Intent.WARNING: {
      return 'is-warning'
    }
    case Intent.DANGER: {
      return 'is-danger'
    }
    default: {
      return null
    }
  }
}

export type HTMLDefaultProps = HTMLAttributes<HTMLElement>
export type HTMLDivProps = HTMLAttributes<HTMLDivElement>
export type HTMLSpanProps = HTMLAttributes<HTMLSpanElement>
export type HTMLParagraphProps = HTMLAttributes<HTMLParagraphElement>
export type HTMLHeadingProps = HTMLAttributes<HTMLHeadingElement>
export type HTMLAnchorProps = AnchorHTMLAttributes<HTMLAnchorElement>
export type HTMLButtonProps = ButtonHTMLAttributes<HTMLButtonElement>
export type HTMLLabelProps = LabelHTMLAttributes<HTMLLabelElement>
export type HTMLInputProps = InputHTMLAttributes<HTMLInputElement>
export type HTMLTextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement>
export type HTMLImageProps = ImgHTMLAttributes<HTMLImageElement>
export type HTMLProgressProps = ProgressHTMLAttributes<HTMLProgressElement>
export type HTMLTableProps = TableHTMLAttributes<HTMLTableElement>

interface BulmaOwnProps {
  hasTextCentered?: boolean
  intent?: Intent
}

export interface BulmaProps extends BulmaOwnProps {
  className?: string
}

// Diff / Omit taken from https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-8.html
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>

export function mapBulmaProps<T extends BulmaProps>(
  props: T,
  baseClassName: string,
): Omit<T, keyof BulmaOwnProps> {
  // TODO: remove any when typescript 3.2 and generic rest release
  const {
    intent,
    hasTextCentered,
    ...other // tslint:disable-line:trailing-comma
  } = props as any

  const className = classNames(
    other.className,
    baseClassName,
    getIntentClass(intent),
    {
      'has-text-centered': hasTextCentered,
    },
  )

  return { ...other, className }
}
