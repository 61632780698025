import { FC } from 'react'
import TSvgProps from './type/svgPropsType'

const CameraIcon: FC<TSvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      aria-label="카메라"
      {...props}
    >
      <desc>카메라</desc>
      <circle cx="15" cy="15" r="15" fill="#666"></circle>
      <g fill="#fff">
        <path d="M21.624 10.248h-1.817l-1-1.514a2.085 2.085 0 00-1.722-.908h-4.167a2.085 2.085 0 00-1.722.908l-1 1.514h-1.82a2.076 2.076 0 00-2.082 2.081v7.343a2.076 2.076 0 002.082 2.082h13.248a2.076 2.076 0 002.082-2.082v-7.343a2.076 2.076 0 00-2.082-2.081zM15 20.048a4.684 4.684 0 114.694-4.672A4.693 4.693 0 0115 20.051zm6.359-6.87h-.833a.616.616 0 010-1.23h.757a.614.614 0 01.643.587.6.6 0 01-.567.646z"></path>
        <path d="M14.999 12.396a2.971 2.971 0 102.981 2.96 2.987 2.987 0 00-2.981-2.96z"></path>
      </g>
    </svg>
  )
}

export default CameraIcon
