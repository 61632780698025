import { FC } from 'react'
import TSvgProps from './type/svgPropsType'

const CafeIcon: FC<TSvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      aria-label="카페"
      {...props}
    >
      <desc>카페</desc>
      <path
        fill="#bbb"
        d="M17 15.938A1.063 1.063 0 0115.938 17H1.063A1.063 1.063 0 010 15.938V1.063A1.063 1.063 0 011.063 0h14.875A1.063 1.063 0 0117 1.063z"
      ></path>
      <path
        fill="#fff"
        d="M3 10.514V7.49h9.754s1.535.2 1.531 2.215a1.957 1.957 0 01-1.554 2.095 3.7 3.7 0 01-.957.037A4.487 4.487 0 013 10.514zm8.972 0q0 .184-.015.363a1.255 1.255 0 001.4-1.281 1.165 1.165 0 00-1.385-1.145zM4.786 6.119C5.427 3 7.612 3 7.612 3h3.605c-.609 2.975-2.67 3.119-2.67 3.119z"
      ></path>
    </svg>
  )
}

export default CafeIcon
