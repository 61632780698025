import { FC } from 'react'
import TSvgProps from './type/svgPropsType'

const UnCheckRadio: FC<TSvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 23 23"
      aria-label="체크 라디오 해제"
      {...props}
    >
      <desc>체크 라디오 해제</desc>
      <g fill="#fff" stroke="#ccc">
        <circle cx="11.5" cy="11.5" r="11.5" stroke="none"></circle>
        <circle cx="11.5" cy="11.5" r="11" fill="none"></circle>
      </g>
    </svg>
  )
}

export default UnCheckRadio
