/* eslint-disable no-new */
import { Plugin } from '@uppy/core'
import Compressor from 'compressorjs'

type UppyCompressorOptions = {
  id?: string
} & Compressor.Options

class UppyCompressor extends Plugin {
  options: UppyCompressorOptions

  constructor(uppy: any, options: UppyCompressorOptions) {
    super(uppy, options)
    this.id = options.id || 'Compressor'
    this.type = 'modifier'
    this.options = options

    this.prepareUpload = this.prepareUpload.bind(this)
    this.compress = this.compress.bind(this)
  }

  compress(blob: any) {
    this.uppy.log(`[Compressor] Image size before compression: ${blob.size}`)
    return new Promise((resolve, reject) => {
      new Compressor(
        blob,
        Object.assign({}, this.options, {
          success: (result: any) => {
            this.uppy.log(
              `[Compressor] Image size after compression: ${result.size}`,
            )
            return resolve(result)
          },
          error: (err: any) => {
            return reject(err)
          },
        }),
      )
    })
  }

  prepareUpload(fileIDs: any) {
    const promises = fileIDs.map((fileID: any) => {
      const file = this.uppy.getFile(fileID)
      if (!file.type) return undefined
      if (file.type.split('/')[0] !== 'image') {
        return undefined
      }
      return this.compress(file.data).then((compressedBlob) => {
        const compressedFile = Object.assign({}, file, { data: compressedBlob })
        this.uppy.setFileState(fileID, compressedFile)
      })
    })
    return Promise.all(promises)
  }

  install() {
    this.uppy.addPreProcessor(this.prepareUpload)
  }

  uninstall() {
    this.uppy.removePreProcessor(this.prepareUpload)
  }
}

export default UppyCompressor
