import { FC } from 'react'
import TSvgProps from './type/svgPropsType'

const TalkIcon: FC<TSvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      aria-label="채팅"
      {...props}
    >
      <desc>채팅</desc>
      <path
        fill="#bbb"
        d="M17 15.938A1.063 1.063 0 0115.938 17H1.063A1.063 1.063 0 010 15.938V1.063A1.063 1.063 0 011.063 0h14.875A1.063 1.063 0 0117 1.063z"
      ></path>
      <path
        fill="#fff"
        d="M4.256 13.561a.569.569 0 01-.175-.668l.522-2.217a4.123 4.123 0 01-1.6-3.178c0-2.48 2.459-4.5 5.5-4.5s5.5 2.02 5.5 4.5-2.459 4.5-5.5 4.5a6.736 6.736 0 01-1.3-.127 205.46 205.46 0 00-2.028 1.621.95.95 0 01-.537.2.584.584 0 01-.382-.131z"
      ></path>
    </svg>
  )
}

export default TalkIcon
