import { css } from 'styled-components'
import { ColorType, VariantType } from './Button.type'

export const getCss = (color: ColorType, variant: VariantType) => {
  switch (color) {
    case 'primary':
      if (variant === 'contained') {
        return css`
          background-color: #01a0ff;
          &:hover {
            background-color: #0098f3;
          }
        `
      }
      if (variant === 'outlined') {
        return css`
          border: 1px solid #01a0ff;
          color: #01a0ff;
          &:hover {
            background-color: #f2faff;
          }
        `
      }
      break
    case 'grey':
      if (variant === 'contained') {
        return css`
          background-color: #999999;
          &:hover {
            background-color: #d5d5d5;
          }
        `
      }
      if (variant === 'outlined') {
        return css`
          border: 1px solid #999999;
          color: #999999;
          &:hover {
            background-color: #d5d5d5;
          }
        `
      }
      break
    case 'lightGrey':
      if (variant === 'contained') {
        return css`
          border: none;
          background-color: #eeeeee;
          color: black;
          &:hover {
            background-color: #d5d5d5;
          }
          &:disabled {
            color: white;
            background-color: #eeeeee;
            border: none;
          }
        `
      }
      if (variant === 'outlined') {
        return css`
          border: 1px solid #eeeeee;
          color: #eeeeee;
          &:hover {
            background-color: #d5d5d5;
          }
        `
      }
      break
    case 'lightGrey2':
      if (variant === 'contained') {
        return css`
          background-color: #eeeeee;
          color: #999999;
          &:hover {
            background-color: #d5d5d5;
          }
        `
      }
      if (variant === 'outlined') {
        return css`
          border: 1px solid #eeeeee;
          color: #999999;
          &:hover {
            background-color: #d5d5d5;
          }
        `
      }
      break
    case 'black':
      if (variant === 'contained') {
        return css`
          background-color: #000000;
          &:hover {
            background-color: #d5d5d5;
          }
        `
      }
      if (variant === 'outlined') {
        return css`
          border: 1px solid #000000;
          color: black;
          &:hover {
            background-color: #d5d5d5;
          }
        `
      }
      break
    case 'red':
      if (variant === 'contained') {
        return css`
          background-color: #ff0066;
          &:hover {
            background-color: rgb(178, 0, 71);
          }
        `
      }
      if (variant === 'outlined') {
        return css`
          border: 1px solid #ff0066;
          &:hover {
            background-color: rgb(178, 0, 71);
          }
        `
      }
      break
    case 'transparent':
      if (variant === 'contained') {
        return css`
          background-color: transparent;
          color: #000000;
          &:hover {
            background-color: #d5d5d5;
          }
        `
      }
      if (variant === 'outlined') {
        return css`
          border: 1px solid transparent;
          color: #000000;
          &:hover {
            background-color: #d5d5d5;
          }
        `
      }
      break
    case 'prediction':
      if (variant === 'contained') {
        return css`
          background-color: #004eff;
          &:hover {
            background-color: #003ee6;
          }
        `
      }
      if (variant === 'outlined') {
        return css`
          border: 1px solid #004eff;
          color: #004eff;
          &:hover {
            background-color: #003ee6;
          }
        `
      }
      break
    default:
      return css``
  }
}
