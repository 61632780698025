import { Overrides } from '@material-ui/core/styles/overrides'
import breakpoints from '../breakpoints'

const MARGIN = 10

const overrides: Overrides = {
  MuiBackdrop: {
    root: {
      backgroundColor: 'rgba(0,0,0, 0.8)',
    },
  },
  MuiDialog: {
    /* Styles applied to the `Paper` component. */
    paper: {
      borderRadius: 20,
      margin: MARGIN,
    },
    /* Styles applied to the `Paper` component if `scroll="paper"`. */
    paperScrollPaper: {
      maxHeight: `calc(100% - ${MARGIN}px)`,
    },
    /* Styles applied to the `Paper` component if `maxWidth=false`. */
    paperWidthFalse: {
      maxWidth: `calc(100% - ${MARGIN}px)`,
    },
    /* Styles applied to the `Paper` component if `maxWidth="xs"`. */
    paperWidthXs: {
      maxWidth: Math.max(breakpoints.values.xs, 444),
      '&$paperScrollBody': {
        [breakpoints.down(Math.max(breakpoints.values.xs, 444) + MARGIN * 2)]: {
          maxWidth: `calc(100% - ${MARGIN}px)`,
        },
      },
    },
    /* Styles applied to the `Paper` component if `maxWidth="sm"`. */
    paperWidthSm: {
      maxWidth: breakpoints.values.sm,
      '&$paperScrollBody': {
        [breakpoints.down(breakpoints.values.sm + MARGIN * 2)]: {
          maxWidth: `calc(100% - ${MARGIN}px)`,
        },
      },
    },
    /* Styles applied to the `Paper` component if `maxWidth="md"`. */
    paperWidthMd: {
      maxWidth: breakpoints.values.md,
      '&$paperScrollBody': {
        [breakpoints.down(breakpoints.values.md + MARGIN * 2)]: {
          maxWidth: `calc(100% - ${MARGIN}px)`,
        },
      },
    },
    /* Styles applied to the `Paper` component if `maxWidth="lg"`. */
    paperWidthLg: {
      maxWidth: breakpoints.values.lg,
      '&$paperScrollBody': {
        [breakpoints.down(breakpoints.values.lg + MARGIN * 2)]: {
          maxWidth: `calc(100% - ${MARGIN}px)`,
        },
      },
    },
    /* Styles applied to the `Paper` component if `maxWidth="xl"`. */
    paperWidthXl: {
      maxWidth: breakpoints.values.xl,
      '&$paperScrollBody': {
        [breakpoints.down(breakpoints.values.xl + MARGIN * 2)]: {
          maxWidth: `calc(100% - ${MARGIN}px)`,
        },
      },
    },
    /* Styles applied to the `Paper` component if `fullWidth={true}`. */
    paperFullWidth: {
      width: `calc(100% - ${MARGIN}px)`,
    },
  },

  MuiDialogTitle: {
    root: {
      paddingTop: 30,
      paddingLeft: 30,
      paddingRight: 30,
      paddingBottom: 0,
      '& h2': {
        fontSize: 18,
        fontWeight: 'bold',
        [breakpoints.down('sm')]: {
          fontSize: 16,
        },
      },
      [breakpoints.down('sm')]: {
        paddingLeft: 20,
        paddingRight: 20,
      },
    },
  },
  MuiDialogContent: {
    root: {
      paddingTop: 20,
      paddingLeft: 30,
      paddingRight: 30,
      [breakpoints.down('sm')]: {
        paddingLeft: 20,
        paddingRight: 20,
      },
    },
  },
  MuiDialogContentText: {
    root: {
      fontSize: 16,
      whiteSpace: 'pre-line',
      fontWeight: 400,
    },
  },
  MuiDialogActions: {
    root: {
      justifyContent: 'center',
      padding: 30,
    },
  },
}

export default overrides
