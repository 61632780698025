import { FC } from 'react'
import TSvgProps from './type/svgPropsType'

const CloseCircleIcon: FC<TSvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.119"
      height="13.119"
      viewBox="0 0 13.119 13.119"
      aria-label="닫기"
      {...props}
    >
      <desc>닫기</desc>
      <g fill="#fff" stroke="#ccc">
        <circle cx="6.5" cy="6.5" r="6.5" stroke="none"></circle>
        <circle cx="6.5" cy="6.5" r="6" fill="none"></circle>
      </g>
      <path
        id="합치기_1"
        d="M4 8.667V5.333H.667A.666.666 0 1 1 .667 4H4V.667a.667.667 0 1 1 1.334 0V4h3.332a.666.666 0 1 1 0 1.333H5.333v3.334a.667.667 0 0 1-1.333 0z"
        fill="#ccc"
        data-name="합치기 1"
        transform="rotate(45 3.3 7.966)"
      />
    </svg>
  )
}

export default CloseCircleIcon
