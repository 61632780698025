import { FC } from 'react'
import TSvgProps from './type/svgPropsType'

const ArrowLeft: FC<TSvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 9.037 16"
      aria-label="왼쪽 화살표"
      {...props}
    >
      <desc>왼쪽 화살표</desc>
      <path
        fill="currentColor"
        d="M7.963 16a1 1 0 00.707-1.707L2.414 8.037l6.33-6.33A1 1 0 107.33.293L.293 7.33a1 1 0 000 1.414l6.964 6.963a1 1 0 00.706.293z"
      ></path>
    </svg>
  )
}

export default ArrowLeft
