import { FC } from 'react'
import TSvgProps from './type/svgPropsType'

const CheckRadioDot: FC<TSvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      aria-label="체크라디오닷"
      {...props}
    >
      <desc>체크라디오닷</desc>
      <circle cx="11.5" cy="11.5" r="11.5" fill="#01a0ff"></circle>
      <circle cx="11.5" cy="11.5" r="4.5" fill="#fff"></circle>
    </svg>
  )
}

export default CheckRadioDot
