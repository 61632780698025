import { FC } from 'react'
import TSvgProps from './type/svgPropsType'

const EmailIcon: FC<TSvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="14.4"
      viewBox="0 0 20 14.4"
      aria-label="이메일"
      {...props}
    >
      <desc>이메일</desc>
      <path
        d="M417.887 163.756a.762.762 0 00-.033-.162.754.754 0 00-.079-.148.8.8 0 00-.05-.094c-.008-.01-.02-.014-.029-.023a.749.749 0 00-.13-.1.466.466 0 00-.252-.12.783.783 0 00-.171-.034c-.012 0-.022-.007-.034-.007h-18.4c-.013 0-.023.007-.036.007a.871.871 0 00-.162.033.81.81 0 00-.14.042.727.727 0 00-.115.078.823.823 0 00-.134.107c-.009.01-.02.014-.028.023a.714.714 0 00-.051.094.8.8 0 00-.079.148.762.762 0 00-.033.162.78.78 0 00-.022.106v12.8a.8.8 0 00.8.8h18.4a.8.8 0 00.8-.8v-12.8a.676.676 0 00-.022-.112zm-1.578 11.173l-4.808-5.374 4.808-3.989zm-16.8-9.363l4.809 3.99-4.809 5.374zm8.4 4.89l-6.982-5.794h13.966zm-2.358.122l1.848 1.533a.8.8 0 001.022 0l1.848-1.534 4.73 5.286h-14.178z"
        transform="translate(-397.909 -163.062)"
      ></path>
    </svg>
  )
}

export default EmailIcon
