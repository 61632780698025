import { ComponentsProps } from '@material-ui/core/styles/props'
import CheckBoxIcon from 'svgIcons/CheckBox'
import CheckRadioIcon from 'svgIcons/CheckRadio'
import CloseIcon from 'svgIcons/Close'
import UnCheckBoxIcon from 'svgIcons/UnCheckBox'
import UnCheckRadioIcon from 'svgIcons/UnCheckRadio'

function ChipDeleteIcon({ ...props }: any) {
  // https://benfrain.com/converting-divs-into-accessible-pseudo-buttons/
  return <CloseIcon {...props} />
}

const componentsProps: ComponentsProps = {
  MuiCheckbox: {
    icon: <UnCheckBoxIcon />,
    checkedIcon: <CheckBoxIcon />,
  },
  MuiRadio: {
    icon: <UnCheckRadioIcon />,
    checkedIcon: <CheckRadioIcon />,
  },
  MuiFormLabel: {
    error: false,
    focused: false,
  },
  MuiInputLabel: {
    error: false,
    focused: false,
  },
  MuiButton: {
    variant: 'contained',
    disableRipple: true,
    disableTouchRipple: true,
    disableFocusRipple: true,
  },
  MuiChip: {
    deleteIcon: <ChipDeleteIcon fontSize="small" />,
  },
  MuiIconButton: {
    disableRipple: true,
  },
  MuiDialogContentText: {
    color: 'textPrimary',
  },
  MuiTab: {
    disableRipple: true,
  },
}

export default componentsProps
