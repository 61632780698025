import { Overrides } from '@material-ui/core/styles/overrides'
import transitions from '@material-ui/core/styles/transitions'
import { LABEL_COLOR } from '../colors'
import palette from '../palette'
import typography from '../typography'

const selectBoxStyle = {
  root: {
    '& svg': {
      color: '#fff',
    },

    '&$disabled': {
      '& svg': {
        color: '#f4f4f4',
      },
    },
  },
  colorPrimary: {
    '&$checked': {
      '& svg': {
        color: palette.primary.main,
      },
    },
  },
  colorSecondary: {
    '&$checked': {
      '& svg': {
        color: palette.secondary.main,
      },
    },
  },
}

const overrides: Overrides = {
  MuiFormLabel: {
    root: {
      fontWeight: 500,
      fontSize: 12,
      color: LABEL_COLOR,
      lineHeight: '1em',
      display: 'flex',
      alignItems: 'center',
      '&$disabled': {
        color: '#999999',
      },
      '&$required': {
        '&:before': {
          content: '""',
          width: 5,
          height: 5,
          marginRight: 5,
          backgroundColor: palette.primary.main,
          display: 'inline-block',
          borderRadius: '50%',
        },
      },
    },

    asterisk: {
      visibility: 'hidden',
      '&:after': {
        content: '•',
      },
    },
  },
  MuiFormGroup: {
    root: {
      flexDirection: 'row',
      marginTop: 10,
    },
  },
  MuiRadio: selectBoxStyle,
  MuiCheckbox: selectBoxStyle,
  MuiFormControlLabel: {
    root: {
      marginLeft: -7,
      marginRight: 30,
    },
    label: {
      fontSize: 16,
      '&$disabled': {
        color: '#bbb',
      },
    },
  },
  MuiOutlinedInput: {
    input: {
      padding: '15.5px 14px',
    },
  },
  MuiFormHelperText: {
    root: {
      ...typography.h4,
      fontWeight: 300,
      marginTop: 0,
      lineHeight: '1em',
      marginLeft: 10,
    },
  },
  MuiInputBase: {
    root: {
      ...typography.body2,
      fontSize: 16,
      lineHeight: '1.1875em', // Reset (19px), match the native input line-height
      '&$disabled': {
        backgroundColor: '#f4f4f4',
        color: 'black',
      },
    },
    inputAdornedEnd: {
      paddingRight: 0,
    },
  },
  MuiInputAdornment: {
    positionEnd: {
      flex: '1 0 auto',
      '& button': {
        paddingRight: 15,
      },
    },
  },
  MuiInput: {
    root: {
      'div + &': {
        marginTop: 10,
      },
      borderRadius: 5,
      border: '1px solid #ced4da',
      transition: transitions.create(['border-color', 'color']),
      '&$focused': {
        borderColor: palette.primary.main,
        color: palette.primary.main,
      },
      '&$error': {
        borderColor: palette.error.main,
        color: palette.error.main,
      },
    },
    input: {
      borderRadius: 5,
      backgroundColor: palette.common.white,
      padding: '15.5px 15px',
      position: 'relative',

      '&$disabled': {
        backgroundColor: '#f4f4f4',
        color: 'black',
      },
    },
  },
  MuiSelect: {
    select: {
      '&:focus': {
        backgroundColor: 'initial',
      },
    },
    selectMenu: {
      borderRadius: 5,
    },
    icon: {
      color: '#2D2D2D',
      top: 0,
      bottom: 0,
      right: 15,
      fontSize: 14,
      margin: 'auto',
    },
  },

  // @ts-ignore
  PrivateSwitchBase: {
    root: {
      padding: 7,
    },
  },
}

export default overrides
