import * as React from 'react'
import TSvgProps from './type/svgPropsType'

const AppleLogo: React.FC<TSvgProps & { fillColor?: string }> = ({
  fillColor = '#000',
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="44"
      viewBox="0 0 24 44"
      aria-label="애플 로고"
      {...props}
    >
      <desc>애플 로고</desc>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        {/* <path fill="#FFF" d="M6 6H50V50H6z"></path> */}
        <path
          fill={fillColor}
          fillRule="nonzero"
          d="M12.234 16.988c.656 0 1.478-.443 1.967-1.035.444-.536.767-1.284.767-2.032 0-.102-.009-.203-.027-.287-.73.028-1.608.49-2.134 1.11-.416.47-.795 1.21-.795 1.967 0 .11.019.222.028.258.046.01.12.019.194.019zm-2.31 11.178c.896 0 1.294-.6 2.411-.6 1.137 0 1.386.582 2.384.582.98 0 1.635-.906 2.254-1.792.693-1.017.98-2.014.998-2.06-.065-.019-1.94-.786-1.94-2.938 0-1.866 1.478-2.707 1.56-2.772-.978-1.404-2.466-1.441-2.872-1.441-1.1 0-1.996.665-2.56.665-.609 0-1.413-.628-2.364-.628-1.81 0-3.65 1.497-3.65 4.323 0 1.756.684 3.613 1.525 4.814.72 1.016 1.349 1.847 2.254 1.847z"
        ></path>
      </g>
    </svg>
  )
}

export default AppleLogo
