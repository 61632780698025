import { FC } from 'react'

const UnCheckBoxIcon: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 23 23"
      aria-label="체크박스 해제"
    >
      <desc>체크박스 해제</desc>
      <g fill="currentColor" stroke="#ccc">
        <rect width="23" height="23" stroke="none" rx="5"></rect>
        <rect
          width="22"
          height="22"
          x="0.5"
          y="0.5"
          fill="none"
          rx="4.5"
        ></rect>
      </g>
    </svg>
  )
}

export default UnCheckBoxIcon
