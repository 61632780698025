import { FC } from 'react'
import TSvgProps from './type/svgPropsType'

const ShareIcon: FC<TSvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      aria-label="공유"
      {...props}
    >
      <desc>공유</desc>
      <path
        fill="currentColor"
        d="M16.035 11.822a3.87 3.87 0 00-2.659 1.076l-5.488-2.156a4.065 4.065 0 00.042-.432 4.155 4.155 0 00-.07-.71l5.476-2.531a3.875 3.875 0 002.7 1.11 4.091 4.091 0 10-3.965-4.089 4.3 4.3 0 00.069.71L6.664 7.331a3.88 3.88 0 00-2.7-1.108 4.091 4.091 0 000 8.178 3.888 3.888 0 002.889-1.306l5.289 2.078a4.113 4.113 0 00-.073.74 3.967 3.967 0 103.965-4.089zm0-9.245a1.513 1.513 0 11-1.465 1.512 1.491 1.491 0 011.465-1.511zm-12.07 9.245a1.512 1.512 0 111.466-1.512 1.491 1.491 0 01-1.466 1.512zm12.07 5.6a1.512 1.512 0 111.465-1.51 1.49 1.49 0 01-1.465 1.51z"
      ></path>
    </svg>
  )
}

export default ShareIcon
