import { FC } from 'react'
import TSvgProps from './type/svgPropsType'

const GiveCardIcon: FC<TSvgProps> = (props) => {
  return (
    <svg
      height="18.2"
      width="26"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="광고문의"
      {...props}
    >
      <desc>광고문의</desc>
      <g fill="#333" transform="translate(0 -67.488)">
        <path
          d="m.741 233.584a.749.749 0 0 0 0-1.5.749.749 0 0 0 0 1.5z"
          transform="translate(0 -154.742)"
        />
        <path
          d="m269.449 161.222a.588.588 0 0 0 -.838 0l-1.8 1.82a.6.6 0 0 0 0 .847.588.588 0 0 0 .838 0l1.8-1.82a.6.6 0 0 0 0-.847z"
          transform="translate(-250.841 -87.956)"
        />
        <path
          d="m295.095 163.6a.588.588 0 0 0 .838 0l3.6-3.641a.6.6 0 0 0 0-.847.588.588 0 0 0 -.838 0l-3.6 3.641a.6.6 0 0 0 0 .847z"
          transform="translate(-277.449 -85.97)"
        />
        <path d="m25.826 73.846-6.116-6.183a.588.588 0 0 0 -.838 0l-2.172 2.2h-7.332a5.626 5.626 0 0 0 -4.362 2.1h-1.155v-.9a.6.6 0 0 0 -.592-.6h-2.667a.6.6 0 0 0 0 1.2h2.074v8.377h-2.074a.6.6 0 0 0 0 1.2h2.666a.6.6 0 0 0 .592-.6v-1.5h1.156a5.626 5.626 0 0 0 4.362 2.1h.689l4.228 4.274a.588.588 0 0 0 .838 0l10.7-10.82a.6.6 0 0 0 0-.847zm-20.05 4.344a.59.59 0 0 0 -.479-.246h-1.446v-4.791h1.449a.59.59 0 0 0 .479-.246 4.446 4.446 0 0 1 3.593-1.85h6.147l-3.519 3.553a6.019 6.019 0 0 1 -1.3-1.325.588.588 0 0 0 -.828-.132.6.6 0 0 0 -.131.837 7.1 7.1 0 0 0 4.67 2.889.674.674 0 0 1 -.208 1.331 8.4 8.4 0 0 1 -4.366-2.1.588.588 0 0 0 -.837.038.6.6 0 0 0 .041.846c.106.1.215.191.325.283l-1.196 1.206a.6.6 0 0 0 0 .847l.671.678a4.457 4.457 0 0 1 -3.065-1.818zm8.924 6.052-5.274-5.336.907-.917a9.6 9.6 0 0 0 3.682 1.4 1.837 1.837 0 0 0 .289.023 1.873 1.873 0 0 0 .287-3.72 5.951 5.951 0 0 1 -1.533-.461l6.233-6.3 5.279 5.339z" />
      </g>
    </svg>
  )
}

export default GiveCardIcon
