import { createTheme } from '@material-ui/core/styles'
import { MuiMediaQueryList } from '@material-ui/core/useMediaQuery'
import breakpoints from './breakpoints'
import componentsProps from './componentsProps'
import overrides from './overrides'
import palette from './palette'
import spacing from './spacing'
import typography from './typography'

interface GenerateThemeProps {
  ssrMatchMedia?: (query: string) => MuiMediaQueryList
}

const generateTheme = ({ ssrMatchMedia }: GenerateThemeProps = {}) =>
  createTheme({
    props: {
      ...componentsProps,
      ...(ssrMatchMedia && {
        MuiUseMediaQuery: { ssrMatchMedia },
      }),
    },
    spacing,
    breakpoints,
    palette,
    typography,
    overrides,
  })

export default generateTheme
