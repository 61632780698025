import {
  ApolloLink,
  FetchResult,
  NextLink,
  Observable,
  Operation,
} from '@apollo/client/core'
import { init as initApm, ApmBase } from '@elastic/apm-rum'
import flow from 'lodash/flow'
import mapKeys from 'lodash/fp/mapKeys'
import mapValues from 'lodash/fp/mapValues'
import omit from 'lodash/fp/omit'
import isEmpty from 'lodash/isEmpty'
import getConfig from 'next/config'
import flatten from 'utils/flat'

const { publicRuntimeConfig } = getConfig()

let _apm: ApmBase | undefined

function canUseAPM() {
  return (
    typeof window !== 'undefined' &&
    publicRuntimeConfig.ELASTIC_APM_SERVICE_NAME
  )
}

export function init() {
  if (_apm) {
    return _apm
  }
  if (canUseAPM()) {
    _apm = initApm({
      serviceName: publicRuntimeConfig.ELASTIC_APM_SERVICE_NAME,
      serverUrl: publicRuntimeConfig.ELASTIC_APM_SERVER_URL,
      pageLoadTransactionName: window.location.pathname,
    })
  }
}

export function getAPM() {
  const isBrowser = typeof window !== 'undefined'
  if (!publicRuntimeConfig.ELASTIC_APM_SERVICE_NAME) {
    return null
  }

  if (isBrowser) {
    if (_apm) {
      return _apm
    }
    if (publicRuntimeConfig.NODE_ENV !== 'production') {
      console.error('init browser APM first')
    }
  }
  // NODE.js SDK
  if (global.__apm__) {
    return global.__apm__ as ApmBase
  }

  return null
}

const sensitiveFields = ['password']

const variablesToLabels = flow([
  omit(sensitiveFields),
  flatten,
  mapKeys((key) => `variables_${String(key).replace(/\./gi, '_')}`),
  mapValues((value) => (isEmpty(value) ? '' : value)),
])

export class ApolloAPMLink extends ApolloLink {
  apm?: ApmBase | null

  constructor() {
    super()
    this.apm = getAPM()
  }

  request(operation: Operation, forward: NextLink): Observable<FetchResult> {
    const subscriber = forward(operation)

    const transaction = this?.apm?.startTransaction(
      operation.operationName,
      'graphql',
    )

    transaction?.addLabels({ operationName: operation.operationName })

    if (!isEmpty(operation.variables)) {
      transaction?.addLabels(variablesToLabels(operation.variables))
    }

    return new Observable((observer) => {
      const subscription = subscriber.subscribe({
        next: (result) => {
          transaction?.end()

          observer.next(result)
        },

        error: (networkError) => {
          const graphQLErrors = networkError?.result?.errors || []
          if (graphQLErrors.length) {
            for (const err of graphQLErrors) {
              this.apm?.captureError(
                JSON.stringify(
                  {
                    message: err.extensions?.exception?.reason,
                    code: err.extensions?.exception?.code,
                    operationName: operation.operationName,
                  },
                  null,
                  ' ',
                ),
              )
            }
          } else {
            this.apm?.captureError(networkError)
          }

          observer.error(networkError)
        },

        complete: observer.complete.bind(observer),
      })

      return () => {
        if (subscription) {
          subscription.unsubscribe()
        }
      }
    })
  }
}
