import { FC } from 'react'
import TSvgProps from './type/svgPropsType'

const FilledArrowIcon: FC<TSvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="12"
      viewBox="0 0 15 12"
      aria-label="채워진화살표"
      {...props}
    >
      <desc>채워진화살표</desc>
      <path
        fill="currentColor"
        d="M5.8 2.714a2 2 0 013.392 0l3.896 6.226a2 2 0 01-1.7 3.06h-7.78a2 2 0 01-1.7-3.06z"
      ></path>
    </svg>
  )
}

export default FilledArrowIcon
