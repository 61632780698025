import { Metric } from 'web-vitals'

// https://github.com/GoogleChrome/web-vitals/#send-the-results-to-google-analytics
function sendReportToGTM(metric: Metric) {
  window.dataLayer.push({
    event: 'web-vitals',
    event_category: 'Web Vitals',
    event_action: metric.name,
    // Google Analytics metrics must be integers, so the value is rounded.
    // For CLS the value is first multiplied by 1000 for greater precision
    // (note: increase the multiplier for greater precision if needed).
    event_value: Math.round(
      metric.name === 'CLS' ? metric.value * 1000 : metric.value,
    ),
    // The `id` value will be unique to the current page load. When sending
    // multiple values from the same page (e.g. for CLS), Google Analytics can
    // compute a total by grouping on this ID (note: requires `eventLabel` to
    // be a dimension in your report).
    event_label: metric.id,
    nonInteraction: true,
  })
}

const notSendedMetricList: Metric[] = []

function reportWebVitals(metric: Metric) {
  if (window.dataLayer) {
    let metricItem: Metric | undefined
    while ((metricItem = notSendedMetricList.pop())) {
      sendReportToGTM(metricItem)
    }
    sendReportToGTM(metric)
  } else {
    notSendedMetricList.push(metric)
  }
}

export default reportWebVitals
