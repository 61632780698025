import { FC } from 'react'
import TSvgProps from './type/svgPropsType'

const BlahIcon: FC<TSvgProps> = (props) => {
  return (
    <svg
      height="23.036"
      viewBox="0 0 26 23.036"
      width="26"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="고객문의"
      {...props}
    >
      <desc>고객문의</desc>
      <g fill="#333">
        <path
          d="m526 145.7a1.079 1.079 0 0 1 -.777-.331l-3.62-3.746h-5.893a2.711 2.711 0 0 1 -2.71-2.706v-9.208a2.711 2.711 0 0 1 2.708-2.709h11.375a2.711 2.711 0 0 1 2.708 2.708v9.208a2.711 2.711 0 0 1 -2.708 2.708v2.993a1.079 1.079 0 0 1 -.678 1 1.109 1.109 0 0 1 -.405.083zm-10.289-17.619a1.627 1.627 0 0 0 -1.625 1.625v9.208a1.627 1.627 0 0 0 1.625 1.625h6.122a.54.54 0 0 1 .389.165l3.78 3.911v-3.535a.542.542 0 0 1 .542-.542h.542a1.627 1.627 0 0 0 1.625-1.625v-9.208a1.627 1.627 0 0 0 -1.625-1.625z"
          transform="translate(-503.792 -127)"
        />
        <path
          d="m499.794 154.161a1.1 1.1 0 0 1 -.408-.08 1.079 1.079 0 0 1 -.678-1v-2.993a2.711 2.711 0 0 1 -2.708-2.713v-8.667a2.711 2.711 0 0 1 2.708-2.708h5.958v1.083h-5.958a1.627 1.627 0 0 0 -1.625 1.625v8.667a1.627 1.627 0 0 0 1.625 1.625h.542a.542.542 0 0 1 .542.542v3.535l3.78-3.911a.54.54 0 0 1 .389-.165h5.039a1.627 1.627 0 0 0 1.625-1.625v-1.083h1.083v1.083a2.711 2.711 0 0 1 -2.708 2.707h-4.809l-3.62 3.746a1.078 1.078 0 0 1 -.777.332z"
          transform="translate(-496 -131.125)"
        />
        <path d="m0 0h9.208v1.083h-9.208z" transform="translate(13 3.792)" />
        <path d="m0 0h9.208v1.083h-9.208z" transform="translate(13 6.5)" />
        <path d="m0 0h4.333v1.083h-4.333z" transform="translate(13 9.208)" />
      </g>
    </svg>
  )
}

export default BlahIcon
