import { FC } from 'react'
import TSvgProps from './type/svgPropsType'

const CompanyIcon: FC<TSvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      aria-label="기업"
      {...props}
    >
      <desc>기업</desc>
      <g
        fill="#fff"
        stroke="currentColor"
        strokeWidth="2"
        transform="translate(3999 19740)"
      >
        <rect
          width="11"
          height="11"
          x="-3994"
          y="-19735"
          stroke="none"
          rx="2"
        ></rect>
        <rect
          width="9"
          height="9"
          x="-3993"
          y="-19734"
          fill="none"
          rx="1"
        ></rect>
      </g>
      <g
        fill="#fff"
        stroke="currentColor"
        strokeWidth="2"
        transform="translate(3999 19740)"
      >
        <rect
          width="12"
          height="16"
          x="-3999"
          y="-19740"
          stroke="none"
          rx="2"
        ></rect>
        <rect
          width="10"
          height="14"
          x="-3998"
          y="-19739"
          fill="none"
          rx="1"
        ></rect>
      </g>
      <g fill="currentColor">
        <path
          d="M-3995-19736h4v2h-4zM-3995-19732h4v2h-4z"
          transform="translate(3999 19740)"
        ></path>
      </g>
    </svg>
  )
}

export default CompanyIcon
