import { FC } from 'react'
import TSvgProps from './type/svgPropsType'

const ListBox: FC<TSvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      aria-label="리스트박스"
      {...props}
    >
      <desc>리스트박스</desc>
      <g stroke="currentColor" fill="transparent">
        <g strokeWidth="2">
          <rect width="16" height="16" stroke="none" rx="3"></rect>
          <rect width="14" height="14" x="1" y="1" fill="none" rx="2"></rect>
        </g>
        <rect width="8" height="2" x="4" y="5" stroke="none" rx="1"></rect>
        <rect width="7" height="1" x="4.5" y="5.5" fill="none" rx="0.5"></rect>
        <g>
          <rect width="8" height="2" x="4" y="9" stroke="none" rx="1"></rect>
          <rect
            width="7"
            height="1"
            x="4.5"
            y="9.5"
            fill="none"
            rx="0.5"
          ></rect>
        </g>
      </g>
    </svg>
  )
}

export default ListBox
