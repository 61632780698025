/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC, useState, useRef, useEffect, useMemo } from 'react'
import S3 from '@uppy/aws-s3'
import Uppy from '@uppy/core'
import { DashboardModal } from '@uppy/react'
import {
  useAttachmentPrepareUploadMutation,
  useAttachmentCompleteUploadMutation,
} from 'generated/graphql'
import UppyCompressor from 'components/UppyCompressor'
import { MIME_TO_EXTENSION } from 'constants/mimeTypes'

export interface FileUploadUtils {
  totalFileSizeLimit?: number
  maxNumberOfFiles?: number
}
export interface FileInfo {
  attachmentID: string
  url: string
  type: string
  name: string
  size: number
}
export interface FileUploadDialogProps extends FileUploadUtils {
  id: string
  path?: string
  getFileInfo: (file: FileInfo[]) => void
  allowedFileTypes?: string[]
  uploadedFileSize?: number
}

interface UploadURL {
  putURL: string
  getURL: string
  meta: any
}

const FileUploadDialog: FC<FileUploadDialogProps> = ({
  id,
  totalFileSizeLimit = 10 * 1000 * 1000,
  maxNumberOfFiles,
  path,
  getFileInfo,
  allowedFileTypes = ['image/*'],
  uploadedFileSize = 0,
}) => {
  const untypedDashboardModalProps = {
    closeAfterFinish: true,
  }
  const [currentUppy, setCurrentUppy] = useState<Uppy.Uppy | null>(null)

  const uppyRef = useRef<Uppy.Uppy | null>(null)
  const CONVERT_SIZE_MAXIMUM = 10 * 1000 * 1000
  const [attachmentPrepareUpload] = useAttachmentPrepareUploadMutation()
  const [attachmentCompleteUpload] = useAttachmentCompleteUploadMutation()

  const allowedExtensionText = useMemo(() => {
    const extensionList = MIME_TO_EXTENSION(allowedFileTypes)
    if (!extensionList || extensionList.length === 0) {
      return ''
    }
    const extensionListString = extensionList
      .map((ext) => ext && ext.toUpperCase())
      .join(', ')
    return ` ㅤ(${extensionListString} 업로드 가능)`
  }, [allowedFileTypes])

  async function createUploadURL(
    name: string,
    size: number,
    type: string,
  ): Promise<UploadURL> {
    const result = await attachmentPrepareUpload({
      variables: {
        name,
        size,
        type,
        path,
      },
    })

    const attachmentPrepareUploadData = result?.data?.attachmentPrepareUpload
    if (!attachmentPrepareUploadData) {
      throw new Error('invalid attachmentPrepareUpload')
    }

    const { signedURL, attachment } = attachmentPrepareUploadData
    return {
      putURL: signedURL,
      getURL: attachment.url,
      meta: attachment.id,
    }
  }

  async function completeUpload(fileInfos: FileInfo[]): Promise<any> {
    await Promise.all(
      fileInfos.map(async (fileInfo) => {
        const attachmentID = fileInfo.attachmentID
        if (attachmentID) {
          await attachmentCompleteUpload({
            variables: { attachmentID },
          })
        }
      }),
    )
  }

  useEffect(() => {
    const uppy = (uppyRef.current = Uppy({
      meta: { type: 'avatar' },
      autoProceed: true,
      allowMultipleUploads: false,
      restrictions: {
        maxFileSize: null,
        maxNumberOfFiles: maxNumberOfFiles || 1,
        minNumberOfFiles: null,
        allowedFileTypes,
      },
      locale: {
        strings: {
          browse: '내 기기에서 찾아보세요',
          dropHereOr: '여기에 파일을 끌어 놓거나 %{browse}',
          dropPaste: '여기에 파일을 끌어 놓거나, 붙여넣거나 %{browse}',
          uploading: '업로드 중',
          youCanOnlyUploadX: '최대 %{smart_count}장까지 등록 가능합니다.',
          youCanOnlyUploadFileTypes: `허용된 확장자의 파일만 업로드할 수 있습니다. (${MIME_TO_EXTENSION(
            allowedFileTypes,
          ).join(', ')})`,
        },
      },
      onBeforeFileAdded: (currentFile, files) => {
        if (!totalFileSizeLimit) {
          return true
        }
        const totalFileSize = Object.keys(files).reduce((prev, filename) => {
          const file = files[filename]
          return prev + file.size
        }, 0)

        const grandTotalFileSize =
          uploadedFileSize + currentFile.data.size + totalFileSize

        if (grandTotalFileSize >= totalFileSizeLimit) {
          uppy.info('파일 업로드 최대 용량을 초과했습니다.', 'error', 3000)
          return false
        }

        return true
      },
    })
      .use(UppyCompressor, { quality: 0.7, convertSize: CONVERT_SIZE_MAXIMUM })
      .use(S3, {
        async getUploadParameters(file) {
          const { getURL, putURL, meta } = await createUploadURL(
            file.name,
            file.size,
            file.type || '',
          )
          uppy.setFileMeta(file.id, { uploadMeta: meta, uploadURL: getURL })

          return {
            method: 'PUT',
            url: putURL,
            fields: {},
          }
        },
      })
      .on('complete', async (result) => {
        uppy.reset()
        const files = result.successful.map((file) => {
          return {
            // @ts-ignore
            attachmentID: file.meta.uploadMeta,
            // @ts-ignore
            url: file.meta.uploadURL,
            size: file.size || 0,
            type: file.type || '',
            name: file.name || 'untitled',
          }
        })
        await completeUpload(files)
        getFileInfo(files)
      }))
    setCurrentUppy(uppy)

    return () => {
      uppy.close()
    }
  }, [totalFileSizeLimit, uploadedFileSize])

  if (!currentUppy) {
    return null
  }

  return (
    <DashboardModal
      trigger={`#uppy-file-upload-trigger-${id}`}
      // @ts-ignore
      target={document?.body}
      uppy={currentUppy}
      disablePageScrollWhenModalOpen={false}
      proudlyDisplayPoweredByUppy={false}
      note={`허용 가능한 파일 크기는 ${
        totalFileSizeLimit / 1000 / 1000
      }MB 입니다.${allowedExtensionText}`}
      {...untypedDashboardModalProps}
    />
  )
}

export default FileUploadDialog
