import { FC } from 'react'
import TSvgProps from './type/svgPropsType'

const FilterIcon: FC<TSvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      aria-label="필터"
      {...props}
    >
      <desc>필터</desc>
      <clipPath id="a">
        <path d="M0 0h24v24H0z"></path>
      </clipPath>
      <g
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeWidth="1.5"
        clipPath="url(#a)"
      >
        <path d="M7 5v7.408"></path>
        <g strokeLinejoin="round">
          <circle
            cx="2"
            cy="2"
            r="2"
            transform="rotate(90 -1.75 10.75)"
          ></circle>
          <path d="M12 11.5V19"></path>
          <circle
            cx="2"
            cy="2"
            r="2"
            transform="rotate(90 3.25 10.75)"
          ></circle>
          <path d="M17 5v7.5"></path>
          <circle
            cx="2"
            cy="2"
            r="2"
            transform="rotate(90 3.25 15.75)"
          ></circle>
          <path d="M17 16.5V19M12 5v2.5"></path>
        </g>
        <path d="M7 16.5V19"></path>
      </g>
    </svg>
  )
}

export default FilterIcon
