import { FC } from 'react'
import TSvgProps from './type/svgPropsType'

interface NaverLogoProps {
  whiteLogo?: boolean
}

const NaverLogo: FC<TSvgProps & NaverLogoProps> = ({
  whiteLogo = false,
  ...props
}) => {
  return (
    <svg
      width="20"
      height="17.686"
      viewBox="0 0 20 17.686"
      aria-label="네이버"
      {...props}
    >
      <desc>네이버</desc>
      <path
        fill={whiteLogo ? 'white' : '#1ec800'}
        d="M0 0h6.958s6.32 9.547 6.507 9.8.213 0 .213 0a16.65 16.65 0 01-.608-4.5V0h6.929v17.69H13.07S6.879 8.653 6.693 8.403s-.212 0-.212 0a11.828 11.828 0 01.449 3.541v5.746H0z"
      ></path>
    </svg>
  )
}

export default NaverLogo
