import { useEffect } from 'react'
import constate from 'constate'
import gql from 'graphql-tag'
import { useCurrentUserQuery } from 'generated/graphql'
import { getAPM } from 'utils/apm'

function useCurrentUser() {
  const { data, loading, error } = useCurrentUserQuery({
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    const apm = getAPM()

    if (data?.currentUser) {
      apm?.setUserContext({
        id: data?.currentUser?.id,
        username: data?.currentUser?.username || '',
        email: data?.currentUser?.email || '',
      })
    }
  }, [data?.currentUser?.id])

  return {
    user: data?.currentUser,
    loading,
    error,
  }
}

export const [CurrentUserProvider, useCurrentUserContext] =
  constate(useCurrentUser)

gql`
  query CurrentUser {
    currentUser {
      id
      username
      email
      type
      isStaff
      avatarUrl
      isSuperuser
      channel {
        id
        url
        deactivatedAt
        owner {
          id
          logoImage {
            id
            url
          }
        }
      }
      scrapCount
    }
  }
`
