import { FC } from 'react'
import TSvgProps from './type/svgPropsType'

interface KakaoLogoProps {
  whiteLogo?: boolean
}

const KakaoLogo: FC<TSvgProps & KakaoLogoProps> = ({ whiteLogo, ...props }) => {
  const textColor = whiteLogo ? 'white' : '#312227'
  const bgColor = whiteLogo ? '#01A0FF' : '#fe0'
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      aria-label="카카오"
      {...props}
    >
      <desc>카카오</desc>
      <path
        d="M191.03 84.89s1.149.117 1.744.117c5.523 0 10-3.467 10-7.745s-4.477-7.745-10-7.745-10 3.468-10 7.745c0 2.652 1.72 4.992 4.343 6.388a7.247 7.247 0 01-2.337 3.628s-.2.338.392.212a23.472 23.472 0 005.858-2.6z"
        fill={textColor}
        transform="translate(-182.774 -69.518) translate(182.774 69.518) translate(-182.774 -69.518)"
      ></path>
      <path
        d="M211.746 137.829h-2.987a.5.5 0 100 1h.971v3.513a.484.484 0 00.146.344.5.5 0 00.35.142h.109a.5.5 0 00.349-.142.485.485 0 00.146-.344v-3.513h.917a.5.5 0 100-1z"
        fill={bgColor}
        transform="translate(-182.774 -69.518) translate(-23.329 -62.659)"
      ></path>
      <path
        d="M307.831 141.111h-1.388v-3.588a.551.551 0 00-1.1 0v3.867a.547.547 0 00.009.087.443.443 0 00-.009.086.457.457 0 00.461.453h2.03a.453.453 0 100-.906z"
        fill={bgColor}
        transform="translate(-182.774 -69.518) translate(-112.236 -61.882)"
      ></path>
      <path
        d="M347.7 141.275l-1.548-2 1.4-1.374a.47.47 0 000-.673.492.492 0 00-.686 0l-1.731 1.7v-1.409a.552.552 0 00-1.1 0v3.986a.552.552 0 001.1 0v-1.23l.315-.309 1.464 1.886a.5.5 0 00.69.1.478.478 0 00.096-.677z"
        fill={bgColor}
        transform="translate(-182.774 -69.518) translate(-147.662 -61.882)"
      ></path>
      <path
        d="M252.256 141.405l-1.489-4a.733.733 0 00-.7-.421.709.709 0 00-.724.5.684.684 0 00-.028.066l-1.464 3.855a.482.482 0 00.3.618.5.5 0 00.63-.3l.253-.71h2.038l.253.71a.5.5 0 00.63.3.482.482 0 00.301-.618zm-2.9-1.294l.685-1.918h.022l.684 1.916z"
        fill={bgColor}
        transform="translate(-182.774 -69.518) translate(-59.564 -61.882)"
      ></path>
    </svg>
  )
}

export default KakaoLogo
