import { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import TSvgProps from './type/svgPropsType'

const useStyle = makeStyles(() => ({
  root: {
    color: '#cccccc',
    '&:hover': {
      color: '#aaaaaa',
    },
  },
}))

const PlusCircleHoverIcon: FC<TSvgProps> = (props) => {
  const classes = useStyle()

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      aria-label="플러스"
      {...props}
      className={clsx(classes.root, props?.className)}
    >
      <desc>플러스</desc>
      <g fill="#fff" stroke="#ccc">
        <circle cx="15" cy="15" r="15" stroke="none"></circle>
        <circle cx="15" cy="15" r="14.5" fill="none"></circle>
      </g>
      <path
        fill="currentColor"
        d="M14 21v-5H9a1 1 0 01-1-1 1 1 0 011-1h5V9a1 1 0 011-1 1 1 0 011 1v5h5a1 1 0 011 1 1 1 0 01-1 1h-5v5a1 1 0 01-1 1 1 1 0 01-1-1z"
      ></path>
    </svg>
  )
}

export default PlusCircleHoverIcon
