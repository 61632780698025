import { Overrides } from '@material-ui/core/styles/overrides'
import palette from '../palette'

const overrides: Overrides = {
  MuiMenu: {
    paper: {
      boxShadow: '3px 3px 3px 0 rgba(0, 0, 0, 0.15)',
      border: `1px solid ${palette.divider}`,
      borderRadius: 5,
    },
    list: {
      paddingTop: 5,
      paddingBottom: 5,
    },
  },
  MuiMenuItem: {
    gutters: {
      paddingLeft: 15,
      paddingRight: 15,
    },
    root: {
      paddingTop: 5,
      paddingBottom: 5,
      fontSize: 12,
    },
  },
}

export default overrides
