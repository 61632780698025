import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { useSnackbar } from 'notistack'
import { stringify } from 'query-string'
import { useGqlChattingContextCurrentUserLazyQuery } from 'generated/graphql'
import {
  CHAT_OPEN_TYPE,
  CHAT_OPEN_TYPE_QUERY_KEY,
} from '../type/chattingQueryParams'
import { TChattingRequestTypes } from '../type/chattingRequest.type'

type TChattingRequestEventListener = <T extends TChattingRequestTypes>(
  event: MessageEvent<T>,
) => void

type TUseChattingRequestMessageHandlerOptions = {
  onRequestClose: () => void
  onRequestSnsShare: (url: string) => void
}

const useChattingRequestMessageHandler = (
  options: TUseChattingRequestMessageHandlerOptions,
) => {
  const router = useRouter()
  const { onRequestClose, onRequestSnsShare } = options
  const [getToken] = useGqlChattingContextCurrentUserLazyQuery()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    const chattingRequestEventListener: TChattingRequestEventListener = async (
      event,
    ) => {
      switch (event.data.type) {
        case 'requestChatClose': {
          onRequestClose()
          return
        }
        case 'requestChatLogin': {
          router.push(
            `/login?${stringify({
              next: `${window.location.href}?${CHAT_OPEN_TYPE_QUERY_KEY}=${CHAT_OPEN_TYPE.DEFAULT}`,
            })}`,
          )
          return
        }
        case 'requestChatSnsShare': {
          onRequestSnsShare(event.data.data.link)
          return
        }
        case 'requestChatCopyText': {
          navigator.clipboard.writeText(event.data.data.copyText)
          enqueueSnackbar('복사되었습니다.')
          return
        }
        case 'requestChatClickLink': {
          window.open(event.data.data.link)
          return
        }
        case 'requestChatToken': {
          const result = await getToken()
          const accessToken = result.data?.currentUser?.tokenSet?.accessToken
          if (accessToken) {
            event.source?.postMessage(
              {
                type: 'reportChatToken',
                data: {
                  token: accessToken,
                },
              },
              {
                targetOrigin: event.origin,
              },
            )
          }
          return
        }
      }
    }

    window.addEventListener('message', chattingRequestEventListener)

    return () => {
      window.removeEventListener('message', chattingRequestEventListener)
    }
  }, [onRequestClose, onRequestSnsShare, router, getToken])
}

export default useChattingRequestMessageHandler
