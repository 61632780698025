import { FC } from 'react'
import TSvgProps from './type/svgPropsType'

const BookMarkBorder: FC<TSvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      aria-label="스크랩"
      {...props}
    >
      <desc>스크랩</desc>
      <defs>
        <clipPath id="15tb0qum5a">
          <path
            fill="#fff"
            stroke="#666"
            d="M0 0h16v16H0z"
            transform="translate(1281 10)"
          ></path>
        </clipPath>
      </defs>
      <g clipPath="url(#15tb0qum5a)" transform="translate(-1281 -10)">
        <path
          fill="none"
          stroke="#666"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M1291.107 12.395h-4.212a2.1 2.1 0 00-2.1 2.1v9.112l4.2-1.659 4.2 1.659v-9.112a2.1 2.1 0 00-2.088-2.1z"
        ></path>
      </g>
    </svg>
  )
}

export default BookMarkBorder
