import { FC } from 'react'
import TSvgProps from './type/svgPropsType'

const ResetIcon: FC<TSvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.001"
      height="10"
      viewBox="0 0 10.001 10"
      aria-label="리셋"
      {...props}
    >
      <desc>리셋</desc>
      <path
        fill="#333"
        d="M5.242.001A5.063 5.063 0 00.006 4.896a5.2 5.2 0 001.3 3.715l-.672.1a.641.641 0 00-.526.732.632.632 0 00.618.548.654.654 0 00.092-.007l2.69-.414-.141-2.685a.632.632 0 00-.658-.608.638.638 0 00-.591.678l.036.7a3.9 3.9 0 01-.9-2.695 3.752 3.752 0 117.491.373 3.849 3.849 0 01-1.967 3.214.654.654 0 00-.253.872.622.622 0 00.551.338.6.6 0 00.3-.077A5.186 5.186 0 005.244.002z"
      ></path>
    </svg>
  )
}

export default ResetIcon
