import { FC } from 'react'
import TSvgProps from './type/svgPropsType'

const ExpandMoreIcon: FC<TSvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="8"
      viewBox="0 0 14 8"
      aria-label="확장"
      {...props}
    >
      <desc>확장</desc>
      <path
        d="M6.933 7.95a.675.675 0 01-.367-.141L.1.957a.533.533 0 010-.742.465.465 0 01.7 0l6.133 6.463L13.1.109a.465.465 0 01.7 0 .533.533 0 010 .742L7.267 7.809a.416.416 0 01-.334.141z"
        transform="translate(-809 546) translate(809.05 -545.95)"
      ></path>
    </svg>
  )
}

export default ExpandMoreIcon
