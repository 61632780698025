import { Overrides } from '@material-ui/core/styles/overrides'

const overrides: Overrides = {
  MuiChip: {
    root: {
      height: 30,
      backgroundColor: '#eeeeee',
    },
    label: {
      fontSize: 12,
    },
    deleteIcon: {
      marginRight: 12,
      marginLeft: -5,
      width: 7,
      height: 7,
    },
    deleteIconColorPrimary: {
      color: '#fff',
    },
  },
}

export default overrides
