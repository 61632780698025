import { Overrides } from '@material-ui/core/styles/overrides'

const overrides: Overrides = {
  MuiBadge: {
    badge: {
      height: 15,
      minWidth: 15,
      padding: '0px 4px',
      borderRadius: 8,
    },
  },
}

export default overrides
