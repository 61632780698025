import { FC } from 'react'
import TSvgProps from './type/svgPropsType'

const NAlphabetIcon: FC<TSvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="5.607"
      height="6.543"
      viewBox="0 0 5.607 6.543"
      aria-label="알파벳 N"
      {...props}
    >
      <desc>알파벳 N</desc>
      <path
        fill="currentColor"
        d="M5.607 6.543H4.023l-2.52-4.562v4.562H0V0h2.017l2.087 3.748V0h1.5z"
      ></path>
    </svg>
  )
}

export default NAlphabetIcon
