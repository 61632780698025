import { getAnalytics, Analytics, logEvent } from 'firebase/analytics'
import { initializeApp, FirebaseApp } from 'firebase/app'
import {
  getMessaging,
  getToken,
  onMessage,
  Messaging,
} from 'firebase/messaging'
import getConfig from 'next/config'
import { Workbox } from 'workbox-window'

const { publicRuntimeConfig } = getConfig()

type TFirebaseModules = {
  firebaseApp: FirebaseApp | null
  messaging: Messaging | null
  token: string | null
  analytics: Analytics | null
}

const firebaseModules: TFirebaseModules = {
  firebaseApp: null,
  messaging: null,
  token: null,
  analytics: null,
}

const firebaseConfig = {
  apiKey: publicRuntimeConfig.FB_API_KEY,
  authDomain: publicRuntimeConfig.FB_AUTH_DOMAIN,
  databaseURL: publicRuntimeConfig.FB_DATABASE_URL,
  projectId: publicRuntimeConfig.FB_PROJECT_ID,
  storageBucket: publicRuntimeConfig.FB_STORAGE_BUCKET,
  messagingSenderId: publicRuntimeConfig.FB_MESSAGING_SENDER_ID,
  appId: publicRuntimeConfig.FB_APP_ID,
  measurementId: publicRuntimeConfig.FB_MEASUREMENT_ID,
}

export const initFirebaseAndMessage = async () => {
  if (
    typeof window === 'undefined' ||
    typeof window.navigator === 'undefined'
  ) {
    return
  }

  try {
    window.workbox = new Workbox('/sw.js', { scope: '/' })
    const serviceWorkerRegistration = await window.workbox?.register()

    firebaseModules.firebaseApp = initializeApp(firebaseConfig)
    firebaseModules.analytics = getAnalytics(firebaseModules.firebaseApp)

    const isNotificationSupported = 'Notification' in window

    if (isNotificationSupported && serviceWorkerRegistration) {
      const handlePushNotification = (data) => {
        if (firebaseModules.analytics) {
          logEvent(firebaseModules.analytics, 'web_notification_receive', {
            messageId: data?.fcmMessageId || data?.messageId,
          })
        }
      }

      const handleNotificationClick = (data) => {
        if (firebaseModules.analytics) {
          logEvent(firebaseModules.analytics, 'web_notification_click', {
            messageId: data?.fcmMessageId || data?.messageId,
          })
        }
      }

      // serviceworker/commonSw.js
      navigator.serviceWorker.addEventListener('message', (event) => {
        console.debug('sw message', event?.data)
        switch (event?.data?.type) {
          case 'pushNotification': {
            // @ts-ignore
            handlePushNotification(event?.data?.data)
            return
          }
          case 'notificationClick': {
            // @ts-ignore
            handleNotificationClick(event?.data?.data)
          }
        }
      })

      const permission = await Notification.requestPermission()
      if (permission === 'granted') {
        const messaging = getMessaging(firebaseModules.firebaseApp)

        firebaseModules.token = await getToken(messaging, {
          vapidKey: publicRuntimeConfig.FB_VAPID_KEY,
          serviceWorkerRegistration,
        })

        console.debug(firebaseModules.token)

        onMessage(messaging, (payload) => {
          console.debug('onMessage', payload)
          if (Notification.permission === 'granted') {
            const title = payload.notification?.title || '알림'
            const url = payload.data?.linkareer_url
            const notificationOption = {
              body: payload.notification?.body,
              icon: payload.notification?.image,
              data: {
                ...(url && {
                  url,
                }),
                ...(payload.messageId && {
                  messageId: payload.messageId,
                }),
              },
            }

            serviceWorkerRegistration?.showNotification(
              title,
              notificationOption,
            )
          }
        })
      }
    }
  } catch (error) {
    console.log(error)
  }
}

export const getFirebaseModules = (): TFirebaseModules => firebaseModules
