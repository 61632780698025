import { FC } from 'react'
import TSvgProps from './type/svgPropsType'

const HamburgerIcon: FC<TSvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      aria-label="메뉴"
      {...props}
    >
      <desc>메뉴</desc>
      <g fill="currentColor">
        <path
          d="M432.151 108.171h-14a1 1 0 010-2h14a1 1 0 010 2zM432.151 101.171h-14a1 1 0 010-2h14a1 1 0 010 2zM432.151 115.171h-14a1 1 0 010-2h14a1 1 0 010 2z"
          transform="translate(-417.151 -99.171)"
        ></path>
      </g>
    </svg>
  )
}

export default HamburgerIcon
