import { FC } from 'react'
import TSvgProps from './type/svgPropsType'

const CheckRadio: FC<TSvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 23 23"
      aria-label="체크라디오"
      {...props}
    >
      <desc>체크라디오</desc>
      <circle cx="11.5" cy="11.5" r="11.5" fill="#01a0ff"></circle>
      <path
        fill="#fff"
        d="M9.851 17a1.04 1.04 0 01-.731-.294L5.3 12.98a1.053 1.053 0 010-1.471 1.1 1.1 0 011.5 0l3.057 3.007L17.2 7.294a1.1 1.1 0 011.5 0 1.023 1.023 0 010 1.471l-8.107 7.941a1.04 1.04 0 01-.742.294z"
      ></path>
    </svg>
  )
}

export default CheckRadio
