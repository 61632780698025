import { FC } from 'react'
import TSvgProps from './type/svgPropsType'

const NewWindowIcon: FC<TSvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.176"
      height="20.416"
      viewBox="0 0 20.176 20.416"
      aria-label="새창"
      {...props}
    >
      <desc>새창</desc>
      <g
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <path d="M10.003 10.398l8.76-8.983M8.094 1.416H1v18h18v-7.625M14.757 1.414h4v4"></path>
      </g>
    </svg>
  )
}

export default NewWindowIcon
