import { getYear } from 'date-fns'
import { RightSectionMenu } from '@app.feature/coverletter/coverletter.write/module/type'

const year = getYear(new Date())

export const LIST_ACTIVITY_META: SeoMetaData = {
  title:
    '대외활동 - 대학생 대외활동 추천, 대외활동 자소서 | 실시간 공고 | 링커리어',
  description:
    '대학생 대외활동 일정 모집 정보를 한 번에! 활동분야에 따라 서포터즈, 해외탐방, 봉사활동, 마케터, 기자단, 멘토링, 강연 등 다양한 대외활동 정보를 추천 받으세요. 관심분야나 활동 혜택, 기간, 지역으로도 구분하여 확인할 수 있습니다.',
  keywords:
    '대외활동, 대학생대외활동, 대외활동사이트, 대외활동합격자료, 서포터즈, 해외탐방, 해외봉사단, 봉사활동, 기자단, 멘토링, 마케터, 국내봉사활동, SNS서포터즈, 강연, 서포터즈면접 대학생서포터즈',
  url: 'https://linkareer.com/list/activity',
  images: [
    {
      url: 'https://linkareer.com/static/images/linkareer_170131.png',
      alt: '링커리어',
    },
  ],
}

export const LIST_CLUB_META: SeoMetaData = {
  title:
    '동아리 - 대학 연합 동아리, 교내, 여행, 봉사 동아리 | 실시간 공고 | 링커리어',
  description:
    '대학생 동아리 일정 모집 정보를 한 번에! 대학 연합 동아리, 교내 동아리, 여행 동아리 등 활동 분야 별 동아리 정보를 추천 받으세요. 관심분야나 지역, 모임대상 등으로도 구분하여 확인할 수 있습니다.',
  keywords:
    '동아리, 대학생동아리, 연합동아리, 동아리추천, 친목동아리, 마케팅동아리, 공모전동아리, 대외활동동아리, 대학교동아리, 동아리모집, 동아리추천, 동호회',
  url: 'https://linkareer.com/list/club',
  images: [
    {
      url: 'https://linkareer.com/static/images/linkareer_170131.png',
      alt: '링커리어',
    },
  ],
}

export const LIST_EDUCATION_META: SeoMetaData = {
  title:
    '교육 - 직무교육, 부트캠프, 내일배움카드, 국비지원ㅣ실시간 공고ㅣ링커리어',
  description:
    '대학생, 취준생에게 필요한 직무교육, 부트캠프, 내일배움카드, 국비지원 교육을 링커리어에서 찾아보세요!',
  keywords:
    '직무교육, 부트캠프, 내일배움카드, 국민내일배움카드, 국비지원, 디지털교육, 프로그래밍, 인공지능, 데이터사이언티스트, 마케팅, 비즈니스, 기획, 금융, 투자, 업무생산성, 영상, 3D, 디자인, 일러스트',
  url: 'https://linkareer.com/list/education',
  images: [
    {
      url: 'https://linkareer.com/static/images/linkareer_170131.png',
      alt: '링커리어',
    },
  ],
}

export const LIST_CONTEST_META: SeoMetaData = {
  title:
    '공모전 - 광고, 건축, 디자인, 사진, 소설, 영상 공모전 | 실시간 공고 | 링커리어 ',
  description:
    '대학생 공모전 정보를 한 번에! 기획, 광고, 사진, 디자인, 건축 등 공모분야 별 공모전 일정 정보를 추천 받으세요. 시상규모, 수상혜택, 응모대상, 주최기관 등으로도 구분하여 확인할 수 있습니다.',
  keywords:
    '공모전, 대학생공모전, 공모전사이트, 공모전정보, 마케팅공모전, 아이디어공모전, 영상공모전, UCC공모전, 네이밍공모전, 건축공모전, 디자인공모전, 문학공모전, 캐릭터공모전, 동화공모전, 영화공모전',
  url: 'https://linkareer.com/list/contest',
  images: [
    {
      url: 'https://linkareer.com/static/images/linkareer_170131.png',
      alt: '링커리어',
    },
  ],
}

export const LIST_INTERN_META: SeoMetaData = {
  title:
    '인턴 - 대기업 인턴, 대학생 인턴, 체험형 인턴, 청년 인턴 | 실시간 공고 | 링커리어',
  description:
    '대학생 인턴, 채용연계형 인턴, 체험형 인턴, 대기업 인턴, 어시스턴트 등 링커리어를 통해 다양한 분야의 인턴 채용 정보를 확인하여 취업하세요! 기업 형태 및 직무, 지역으로 구분하여 검색할 수 있습니다.',
  keywords: '스타트업 인턴, 대학생 인턴, 체험형 인턴, 인턴 채용, 청년 인턴',
  url: 'https://linkareer.com/list/intern',
  images: [
    {
      url: 'https://linkareer.com/static/images/linkareer_170131.png',
      alt: '링커리어',
    },
  ],
}

export const LIST_RECRUIT_META: SeoMetaData = {
  title: '채용 - 대기업, 공기업, 스타트업 채용 | 실시간 공고 | 링커리어',
  description:
    '대학생 신입, 경력 채용, 공채, 대기업 채용, 공기업 채용, 중견기업 채용, 외국계기업 채용, 스타트업 채용 등 링커리어를 통해 다양한 채용 정보를 확인하여 취업하세요! 기업 형태 및 채용 형태, 직무, 지역으로 구분하여 검색할 수 있습니다.',
  keywords: '채용, 경력 채용, 공채, 대기업 채용, 공기업 채용',
  url: 'https://linkareer.com/list/recruit',
  images: [
    {
      url: 'https://linkareer.com/static/images/linkareer_170131.png',
      alt: '링커리어',
    },
  ],
}

export const CALENDAR_META: SeoMetaData = {
  title: '공고 달력 - 대외활동, 공모전, 인턴, 채용 공고 달력 | 링커리어',
  description:
    '대기업 채용, 대학생 대외활동, 인턴, 공모전, 동아리 등의 공고 일정을 공고 달력으로 한 번에 확인하세요! 관심 있는 공고를 스크랩 하여 공고 일정을 한 번에 관리할 수 있습니다.',
  keywords: '공고달력,공채달력,채용달력,채용일정,공채일정',
  url: 'https://linkareer.com/calendar',
  images: [
    {
      url: 'https://linkareer.com/static/images/linkareer_170131.png',
      alt: '링커리어',
    },
  ],
}

export const CHANNEL_META: SeoMetaData = {
  title: '채널 - 인기 있는 기업 소식 확인하기 | 링커리어',
  description:
    '지금 가장 인기 있는 기업 소식과 내가 관심 있는 기업 소식을 확인해보세요. 대학생 대외활동 공모전 대학생 인턴 체험형 인턴 채용 동아리 모집 정보를 찾고 있다면, 링커리어 기업채널을 이용해보세요!',
  keywords: '대학생,대외활동,공모전,봉사활동,인턴 채용',
  url: 'https://linkareer.com/channel',
  images: [
    {
      url: 'https://linkareer.com/static/images/channel/channel-seo.png',
      alt: '링커리어',
    },
  ],
}

export const COVER_LETTER_META: SeoMetaData = {
  title: `자소서 만능 검색기 - ${year} 합격 자기소개서 검색 | 링커리어`,
  description: `${year}년 최신 합격 자소서를 검색해보세요. 성장 과정, 지원 동기, 입사 후 포부 등 자기소개서 예시를 확인해보세요. 기업명, 직무에 따라 검색할 수 있으며 합격자들의 합격 스펙도 확인할 수 있습니다.`,
  keywords:
    '합격자소서, 취업자소서, 자소서경험, 인턴 자소서, 자기소개서양식, 자기소개서, 합격자소서 검색, 상반기 자소서, 하반기 자소서, 합격자소서 모음, 자기소개서예시, 자소서지원동기예시, 자기소개서자유양식, 자소서성장과정, 자소서예시, 링커리어, 취업사이트, 스펙업',
  url: 'https://linkareer.com/cover-letter/search',
}

export const SELF_INTRODUCTION_META: {
  [tab in RightSectionMenu]: SeoMetaData
} = {
  'cover-letter': {
    title: '자소서 작성 - 합격 자소서 예시 | 링커리어',
    description:
      '질문과 내용을 입력하고, 글자 수 세기 기능을 통해 간편하게 이력서 및 자기소개서를 작성하세요. 합격 자소서 예시도 한 눈에 비교하며 작성할 수 있습니다.',
    keywords:
      '합격자소서, 취업자소서, 자소서경험, 인턴 자소서, 자기소개서양식, 자기소개서, 합격자소서 검색, 상반기 자소서, 하반기 자소서, 합격자소서 모음, 자기소개서예시, 자소서지원동기예시, 자기소개서자유양식, 자소서성장과정, 자소서예시, 링커리어, 취업사이트',
    url: 'https://linkareer.com/self-introduction',
    images: [
      {
        url: 'https://linkareer.com/static/images/linkareer_170131.png',
        alt: '링커리어',
      },
    ],
  },
  'spell-check': {
    title: '자소서 작성 - 맞춤법 검사 | 링커리어',
    description:
      '맞춤법 검사 기능을 통해 간편하게 이력서와 자기소개서를 작성하세요. 새 자기소개서를 저장한 후, 내보내기 및 불러오기가 가능합니다.',
    keywords:
      '합격자소서, 취업자소서, 자소서경험, 인턴 자소서, 자기소개서양식, 자기소개서, 합격자소서 검색, 상반기 자소서, 하반기 자소서, 합격자소서 모음, 자기소개서예시, 자소서지원동기예시, 자기소개서자유양식, 자소서성장과정, 자소서예시, 링커리어, 취업사이트',
    url: 'https://linkareer.com/self-introduction&tab=spell-check',
    images: [
      {
        url: 'https://linkareer.com/static/images/linkareer_170131.png',
        alt: '링커리어',
      },
    ],
  },
  snapshot: {
    title: '저장기록 - 링커리어',
    description:
      '저장기록보며 간편히 이력서, 자기소개서 수정하세요. 합격 자소서 예시도 한 눈에 비교하며 합격하는 자소서 작성 가능합니다. 맞춤법 검사 제공.',
    keywords:
      '합격자소서, 취업자소서, 자소서경험, 인턴 자소서, 자기소개서양식, 자기소개서, 합격자소서 검색, 상반기 자소서, 하반기 자소서, 합격자소서 모음, 자기소개서예시, 자소서지원동기예시, 자기소개서자유양식, 자소서성장과정, 자소서예시, 링커리어, 취업사이트',
    url: 'https://linkareer.com/self-introduction&tab=snapshot',
    images: [
      {
        url: 'https://linkareer.com/static/images/linkareer_170131.png',
        alt: '링커리어',
      },
    ],
  },
}

export const DEFAULT_META: SeoMetaData = {
  title: '링커리어 LINKareer | 대외활동 공모전 대학생 인턴 대기업 채용정보',
  description:
    '대기업 채용, 인턴, 대학생 대외활동, 공모전, 동아리 등 개인에게 맞춤화된 커리어 정보를 추천받으세요! 커뮤니티에서 고민을 나누고, 인턴 및 신입 합격 후기도 확인 할 수 있습니다. 관심 있는 기업 소식도 받아보세요.',
  keywords: '대학생,대외활동,공모전,봉사활동,인턴 채용',
  url: 'https://linkareer.com',
  images: [
    {
      url: '/images/ic-launcher-144.png',
      alt: '링커리어',
    },
    {
      url: 'https://linkareer.com/images/ic-launcher-192.png',
      width: 192,
      height: 192,
      alt: '링커리어',
    },
    {
      url: 'https://linkareer.com/images/ic-launcher-512.png',
      width: 512,
      height: 512,
      alt: '링커리어',
    },
  ],
}

interface SeoMetaData {
  title: string
  description: string
  keywords: string
  url: string
  images?: SeoMetaDataImage[]
}

interface SeoMetaDataImage {
  url: string
  alt?: string
  width?: number
  height?: number
}
