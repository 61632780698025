import { Overrides } from '@material-ui/core/styles/overrides'

const overrides: Overrides = {
  MuiStepLabel: {
    label: {
      color: '#bbbbbb',
      fontSize: 16,
      fontWeight: 500,
      '&$alternativeLabel': {
        marginTop: 8,
      },
    },
  },
}

export default overrides
