import React, { ComponentProps } from 'react'

type TProps = { isActive?: boolean; color?: string } & ComponentProps<'svg'>

const Note: React.FC<TProps> = ({
  isActive = false,
  color = '#333333',
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      aria-label="노트"
      {...props}
    >
      <desc>노트</desc>
      <path fill="none" d="M0 0h20v20H0z" data-name="사각형 10785"></path>
      <g data-name="그룹 11422">
        <g
          fill="none"
          stroke={isActive ? '#01a0ff' : color}
          strokeWidth="2"
          data-name="사각형 11181"
          transform="translate(2 2)"
        >
          <rect width="16" height="16" stroke="none" rx="3"></rect>
          <rect width="14" height="14" x="1" y="1" rx="2"></rect>
        </g>
        <path
          fill={isActive ? '#01a0ff' : color}
          d="M7 7h6a1 1 0 010 2H7a1 1 0 010-2z"
          data-name="사각형 11182"
        ></path>
        <path
          fill={isActive ? '#01a0ff' : color}
          d="M7 11h6a1 1 0 010 2H7a1 1 0 010-2z"
          data-name="사각형 11183"
        ></path>
      </g>
    </svg>
  )
}

export default Note
